import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from './utils';
import { Spinner } from './Spinner';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        destructive: 'bg-red-500 text-white hover:bg-red-500/90',
        destructiveSubtle: 'bg-red-100 text-red-500 hover:bg-red-100/90',
        yellow: 'bg-button-yellow-fill text-button-yellow-text ',
        green: 'bg-button-green-fill text-button-green-text outline-button-green-outline',
        outline: 'border border-gray-400 bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2 text-sm',
        sm: 'h-9 rounded-md px-3 text-sm',
        lg: 'h-11 rounded-md px-8 text-base',
        icon: 'h-10 w-10 text-sm',
      },
    },
    defaultVariants: {
      variant: 'green',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, isLoading, disabled, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const isDisabled = disabled || isLoading;
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} disabled={isDisabled} ref={ref} {...props}>
        {isLoading && <Spinner size="small" className="me-2" />}
        {children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';
