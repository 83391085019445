import { type ReactNode } from 'react';
import { useUserProfile } from 'services/api';

export type Policy =
  | {
      allPolicies: string[];
    }
  | {
      anyPolicy: string[];
    }
  | {
      singlePolicy: string;
    };

type AuthorizeProps = {
  children: ReactNode;
  fallback?: ReactNode;
  policy: Policy;
};

export const Authorize = ({ policy, children, fallback = null }: AuthorizeProps) => {
  const { data } = useUserProfile();

  if (!policy) {
    throw new Error('You must specify a policy');
  }

  let hasAccess = false;

  if ('allPolicies' in policy) {
    hasAccess = policy.allPolicies.every((p) => data?.grantedPolicies?.[p] === true);
  } else if ('anyPolicy' in policy) {
    hasAccess = policy.anyPolicy.some((p) => data?.grantedPolicies?.[p] === true);
  } else if ('singlePolicy' in policy) {
    hasAccess = data?.grantedPolicies?.[policy.singlePolicy] === true;
  }

  return hasAccess ? children : fallback;
};
