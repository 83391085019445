import { type ToolboxResponse } from 'services/api';

import { Tile } from './Tile';

export const PersonalProjectsTile = ({
  personalProjects,
}: {
  personalProjects: ToolboxResponse['personalProjects'];
}) => {
  return (
    <Tile title={personalProjects.title} icon={personalProjects.logo}>
      <p className="prose text-primary prose-sm">{personalProjects.description.localizedDescription}</p>
    </Tile>
  );
};
