import * as React from 'react';

import { Heading, Icon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { useMediaQuery } from 'utils/hooks';
import { PersonalitySurveyResults } from 'services/api';

import { PersonalityMetrics } from './PersonalityMetrics';
import { PersonalityChart } from './PersonalityChart';
import { HowToRead } from './HowToRead';
import { WhatTheGraphSays } from './WhatTheGraphSays';

type SectionWithImageProps = {
  title: string;
  data: PersonalitySurveyResults['decisionMaking'] | PersonalitySurveyResults['communications'];
};

function SectionWithImage({ title, data }: SectionWithImageProps) {
  return (
    <div>
      <Heading type="h3" className="mb-4">
        {title}
      </Heading>

      <div className="space-y-4">
        {data.map((datum, index) => (
          <div className="flex md:items-center" key={index}>
            <div className="me-4 max-h-[6rem] max-w-[6rem] overflow-clip">
              <img
                src={datum.gifUrl}
                className="min-h-[6rem] min-w-[6rem] rounded-lg object-cover"
                alt={datum.localizedType}
              />
            </div>
            <div>
              <div className="text-brand-yellow font-bold uppercase">{datum.localizedType}</div>
              <p className="prose-sm text-primary prose">{datum.localizedDescription}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export const YourPersonality = ({
  strengths,
  decisionMaking,
  professionalBehaviors,
  graphIntroduction,
  communications,
}: {
  strengths: PersonalitySurveyResults['strengths'];
  decisionMaking: PersonalitySurveyResults['decisionMaking'];
  professionalBehaviors: PersonalitySurveyResults['professionalBehaviors'];
  communications: PersonalitySurveyResults['communications'];
  graphIntroduction: string;
}) => {
  const t = useTranslate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div>
      <Heading iconName="personality-result" className="mb-4" type="h2" font="body">
        {t('orientation.personality_survey.results.your_personality.global_description')}
      </Heading>

      <HowToRead />

      {isMobile ? (
        <PersonalityMetrics professionalBehaviors={professionalBehaviors} />
      ) : (
        <PersonalityChart professionalBehaviors={professionalBehaviors} />
      )}

      <WhatTheGraphSays graphIntroduction={graphIntroduction} />

      <Heading type="h3" className="mb-2">
        {t('orientation.personality_survey.results.your_personality.your_strenghts_subtitle')}
      </Heading>

      <p className="prose text-primary mb-4">
        {t('orientation.personality_survey.results.your_personality.your_strenghts_content')}
      </p>

      <ul className="mb-8 space-y-3">
        {strengths.map((strength, idx) => (
          <li key={idx} className="flex items-center justify-start leading-tight">
            <Icon name="radio" className="text-brand-yellow mt-2 min-w-[24px] text-2xl" />
            {strength.localizedName}
          </li>
        ))}
      </ul>

      <div className="space-y-8">
        {
          <SectionWithImage
            title={t('orientation.personality_survey.results.your_personality.how_you_communicate_subtitle')}
            data={communications}
          />
        }

        <SectionWithImage
          title={t('orientation.personality_survey.results.your_personality.how_to_make_decisions_subtitle')}
          data={decisionMaking}
        />
      </div>
    </div>
  );
};
