import { Fragment } from 'react';
import clsx from 'clsx';
import { m } from 'framer-motion';

import { Icon, type IconProps } from './Icon';

type Step = {
  title: string;
  isCompleted: boolean;
  isCurrent?: boolean;
  iconName: IconProps['name'];
};

export type StepperProps = {
  steps: Step[];
  className?: string;
  initialAnimate?: boolean;
};

export const Stepper = ({ steps, className, initialAnimate }: StepperProps) => {
  const firstUncompletedIndex = steps.findIndex((step) => !step.isCompleted);

  return (
    <div className={clsx('flex w-full items-center', className)}>
      {steps.map((step, idx) => {
        return (
          <Fragment key={idx}>
            <div className="z-10 flex flex-col items-center">
              <m.div
                className="text-brand-pink flex rounded-full bg-red-500 p-1"
                initial={initialAnimate ? { backgroundColor: step.isCompleted ? 'currentcolor' : '#fff' } : false}
                animate={{
                  backgroundColor: step.isCompleted
                    ? 'currentcolor'
                    : firstUncompletedIndex === idx
                      ? '#fb91e4'
                      : '#fff',

                  transition: {
                    duration: 0.5,
                    delay: firstUncompletedIndex === idx ? 0.5 : 0,
                    ease: 'linear',
                  },
                }}
              >
                <span
                  className={clsx(
                    '2xs:h-10 2xs:w-10 flex h-8 w-8 shrink-0 items-center justify-center rounded-full lg:h-12 lg:w-12',
                    step.isCompleted ? 'bg-[#fb91e4]' : 'bg-white',
                  )}
                >
                  <Icon name={step.iconName} className={clsx(step.isCompleted ? 'text-white' : 'text-contrast')} />
                </span>
              </m.div>
              {step.title && (
                <span
                  className={clsx(
                    'text-sm font-medium text-gray-500',
                    step.isCompleted ? 'text-brand-pink font-bold' : 'text-primary',
                  )}
                >
                  {step.title}
                </span>
              )}
            </div>
            {idx !== steps.length - 1 &&
              (firstUncompletedIndex - 1 === idx ? (
                <div
                  className={clsx('-ms-8 -me-8 h-1 flex-1', 'bg-white', {
                    'mb-4': !!step.title,
                  })}
                >
                  <m.div
                    initial={initialAnimate ? { width: 0 } : false}
                    animate={{
                      width: '100%',
                      transition: {
                        duration: 1.5,
                        ease: 'easeInOut',
                      },
                    }}
                    className="bg-brand-pink/50 h-1"
                  />
                </div>
              ) : (
                <div
                  className={clsx('-ms-8 -me-8 h-1 flex-1', step.isCompleted ? 'bg-brand-pink/40' : 'bg-white', {
                    'mb-4': !!step.title,
                  })}
                />
              ))}
          </Fragment>
        );
      })}
    </div>
  );
};
