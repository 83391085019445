import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';

import { paths, useApi } from './api';

export type MentorsResponse = paths['/api/mentors']['post']['responses']['200']['content']['application/json'];

export const useMentors = ({
  filters = [],
}: {
  filters?: {
    name: string;
    operator: string;
    value: string | string[];
  }[];
}) => {
  const api = useApi();
  const { language } = useIntlState();
  const metadataQuery = useMentorsMetadata();

  return useInfiniteQuery({
    queryKey: ['mentors', language, filters],
    queryFn: async ({ signal, pageParam }) => {
      const response = await api!.post(`mentors`, {
        json: {
          pageSize: 2,
          ...(pageParam ? { offset: pageParam as string } : {}),
          filters,
        },
        signal,
      });

      return response.json<MentorsResponse>();
    },
    getNextPageParam: (lastPage) => lastPage.offset,
    enabled: !!api && !!metadataQuery.data,
  });
};

export type MetadataResponse = paths['/api/mentors/metadata']['get']['responses']['200']['content']['application/json'];

export const useMentorsMetadata = () => {
  const api = useApi();
  const { language } = useIntlState();

  return useQuery({
    queryKey: ['mentors', 'metadata', language],
    queryFn: async ({ signal }) => {
      const response = await api!.get(`mentors/metadata`, {
        signal,
      });

      return response.json<MetadataResponse>();
    },
    enabled: !!api,
  });
};

export type MentorsOptionsResponse =
  paths['/api/mentors/options']['get']['responses']['200']['content']['application/json'];

export const useMentorsOptions = () => {
  const api = useApi();
  const { language } = useIntlState();

  return useQuery({
    queryKey: ['mentors', 'options', language],
    queryFn: async ({ signal }) => {
      const response = await api!.get(`mentors/options`, {
        signal,
      });

      return response.json<MentorsOptionsResponse>();
    },
    enabled: !!api,
  });
};
