import { useQuery } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';

import { useApi } from './api';

export const useTrendsAndInsights = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['results', 'trendsAndInsights', mappedLanguage],
    queryFn: async () => {
      const response = await api!.get('assessment-outputs/trends-and-insights', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<unknown>();
    },
    enabled: !!api,
  });
};
