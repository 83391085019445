import { Button, Icon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

type ExpandButtonProps = {
  isOpen: boolean;
  className?: string;
  onToggle: () => void;
};

export const ExpandButton = ({ isOpen, className, onToggle }: ExpandButtonProps) => {
  const t = useTranslate();

  return (
    <Button
      variant="ghost"
      onClick={onToggle}
      size="sm"
      className={className}
      aria-label={isOpen ? t('general.collapse') : t('general.expand')}
    >
      <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
    </Button>
  );
};
