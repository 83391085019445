import { useState } from 'react';
import clsx from 'clsx';

import { type ToolboxResponse } from 'services/api';

import { Tile } from './Tile';
import { ScrollableIconSection } from './ScrollableIconSection';

export const WorkEnvironments = ({ workEnvironments }: { workEnvironments: ToolboxResponse['workEnvironments'] }) => {
  const [index, setIndex] = useState(0);

  const realIndex =
    ((index % workEnvironments.items.length) + workEnvironments.items.length) % workEnvironments.items.length;

  const currentOrganization = workEnvironments.items[realIndex];

  const longestItemIndex = workEnvironments.items.reduce((acc, item, i) => {
    if (item.title.length > workEnvironments.items[acc].title.length) {
      return i;
    }
    return acc;
  }, 0);

  return (
    <Tile title={workEnvironments.title} icon={workEnvironments.logo}>
      <ScrollableIconSection onBack={() => setIndex((x) => x - 1)} onForward={() => setIndex((x) => x + 1)}>
        <div className="relative flex flex-col">
          {workEnvironments.items.map((item, i) => (
            <div
              key={i}
              className={clsx(
                'flex h-full w-full items-center justify-center text-center font-bold',
                i === longestItemIndex ? 'relative' : 'absolute',
                i === realIndex ? 'visible opacity-100' : 'invisible opacity-0',
              )}
            >
              {item.title}
            </div>
          ))}
        </div>
      </ScrollableIconSection>

      <p className="prose text-primary prose-sm mt-2 text-center">{currentOrganization.text}</p>
    </Tile>
  );
};
