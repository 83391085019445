import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';
import { withArtificialDelay } from 'utils/promises';

import { isHTTPError, useApi } from './api';
import { paths } from './types';

type CurrentSectorOfInterestsResponse =
  paths['/api/interest-sectors']['get']['responses']['200']['content']['application/json'];

export const useSectorsOfInterest = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['sectorsOfInterest', mappedLanguage],
    queryFn: async () => {
      try {
        const response = await api!.get('interest-sectors', {
          searchParams: new URLSearchParams({
            languageCode: mappedLanguage,
          }),
        });

        return response.json<CurrentSectorOfInterestsResponse>();
      } catch (error) {
        if (isHTTPError(error)) {
          if (error.response.status === 404) {
            return null;
          }
        }

        throw error;
      }
    },
    enabled: !!api,
  });
};

type SectorsOfInterestOptions =
  paths['/api/interest-sectors/options']['get']['responses']['200']['content']['application/json'];

export const useSectorsOfInterestOptions = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['sectorsOfInterestOptions', mappedLanguage],
    queryFn: async () => {
      const response = await api!.get('interest-sectors/options', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<SectorsOfInterestOptions>();
    },
    enabled: !!api,
  });
};

type SectorsOfInterestPreviewResponse =
  paths['/api/interest-sectors/preview-description']['get']['responses']['200']['content']['application/json'];

export const useSectorsOfInterestPreview = (
  primaryInterestSectorId: number | null,
  secondaryInterestSectorId: number | null,
) => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['sectorsOfInterestPreview', mappedLanguage, primaryInterestSectorId, secondaryInterestSectorId],
    queryFn: async () => {
      const response = await api!.get('interest-sectors/preview-description', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
          primaryInterestSectorId: primaryInterestSectorId!.toString(),
          ...(secondaryInterestSectorId ? { secondaryInterestSectorId: secondaryInterestSectorId.toString() } : {}),
        }),
      });

      return response.json<SectorsOfInterestPreviewResponse>();
    },
    enabled: !!api && primaryInterestSectorId != null,
    keepPreviousData: !!primaryInterestSectorId,
  });
};

type UpdateSectorsOfInterestRequestBody =
  paths['/api/interest-sectors']['post']['requestBody']['content']['application/json'];
type UpdateSectorsOfInterestResponseBody =
  paths['/api/interest-sectors']['post']['responses']['200']['content']['application/json'];

export const useUpdateSectorsOfInterest = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateSectorsOfInterestRequestBody) => {
      const response = await withArtificialDelay(
        api!.post('interest-sectors', {
          json: data,
        }),
      );

      return response.json<UpdateSectorsOfInterestResponseBody>();
    },
    onSuccess: async (data) => {
      queryClient.setQueriesData(['sectorsOfInterest'], data);
      await queryClient.invalidateQueries(['assessmentsStatus']);
      await queryClient.invalidateQueries(['onboardingStatus']);
      await queryClient.invalidateQueries(['results']);
    },
  });
};
