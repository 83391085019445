import { useAuth } from 'oidc-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from './api';
import { paths } from './types';

export type UserProfile = paths['/api/profile']['get']['responses']['200']['content']['application/json'];

export const useUserProfile = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['userProfile'],

    queryFn: async () => {
      const response = await api!.get('profile');

      return response.json<UserProfile>();
    },

    enabled: !!api,
  });
};

export const useUpdateProfilePicture = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: FormData) => {
      const response = await api!.post('profile/change-photo', {
        headers: {
          'Content-Type': undefined,
        },
        body: formData,
      });

      return response.text();
    },
    onSuccess: (profilePicture) => {
      queryClient.setQueryData(['userProfile'], (oldData: UserProfile) => ({
        ...oldData,
        profilePicture,
      }));
    },
  });
};

type ChangePasswordRequestBody =
  paths['/api/profile/change-password']['post']['requestBody']['content']['application/json'];

export const useChangePassword = () => {
  const api = useApi();

  return useMutation({
    mutationFn: async (data: ChangePasswordRequestBody) => {
      await api!.post('profile/change-password', {
        json: data,
      });
    },
  });
};

type ChangeEmailRequestBody = paths['/api/profile/change-email']['post']['requestBody']['content']['application/json'];

export const useChangeEmail = () => {
  const api = useApi();

  return useMutation({
    mutationFn: async (data: ChangeEmailRequestBody) => {
      await api!.post('profile/change-email', {
        json: data,
      });
    },
  });
};

type UpdateProfileRequestBody = paths['/api/profile']['post']['requestBody']['content']['application/json'];

export const useUpdateProfile = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateProfileRequestBody) => {
      const response = await api!.post('profile', {
        json: data,
      });

      const responseData = await response.json<UserProfile>();

      queryClient.setQueryData(['userProfile'], responseData);

      return responseData;
    },
  });
};

type DeleteAccountResponseBody =
  paths['/api/profile/delete-account']['post']['responses']['200']['content']['application/json'];

export const useDeleteAccount = () => {
  const api = useApi();
  const { userManager } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const response = await api!.post('profile/delete-account');

      return response.json<DeleteAccountResponseBody>();
    },
    onSuccess: async () => {
      await userManager.signoutRedirect();
    },
  });
};
