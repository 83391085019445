import * as React from 'react';

import { Icon as IconifyIcon } from '@iconify/react';

import { iconMap } from './icons';

import './iconify-bundle/iconify-bundle';

export type IconProps = {
  name?: keyof typeof iconMap;
  altName?: string;
  color?: string;
  className?: string;
};

export const Icon = React.memo(({ name, altName, color, className }: IconProps) => {
  return <IconifyIcon icon={iconMap[name!] || altName} fontSize="1.375rem" color={color} className={className} />;
});
