import { useTranslate } from '@interskillar/localization';
import { Heading, PageLoader } from '@interskillar/ui';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ToolboxResults } from 'components/ToolboxResults';
import { CAREER_ORIENTATION_PAGE } from 'consts/pages';
import { useToolbox } from 'services/api';

export const ToolboxPage = () => {
  const t = useTranslate();
  const toolboxQuery = useToolbox();

  if (toolboxQuery.isLoading || !toolboxQuery.data) {
    return <PageLoader />;
  }

  return (
    <div className="max-w-xl pb-14">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.career_orientation',
            href: CAREER_ORIENTATION_PAGE.path,
          },
          {
            label: 'breadcrumbs.toolbox',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading iconName="wand" className="mb-4">
        {t('orientation.toolbox.title')}
      </Heading>

      <ToolboxResults data={toolboxQuery.data} />
    </div>
  );
};
