import * as React from 'react';
import cx from 'clsx';

export type LabelProps = {
  htmlFor?: string;
  className?: string;
  children: React.ReactNode;
};

export const Label = ({ htmlFor, className, children }: LabelProps) => {
  return (
    <label htmlFor={htmlFor} className={cx('text-primary mb-1 block text-base font-medium', className)}>
      {children}
    </label>
  );
};
