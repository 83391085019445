export const iconMap = {
  send: 'ic:baseline-send',
  stopwatch: 'mdi:stopwatch',
  'chevron-left': 'tabler:chevron-left',
  'chevron-right': 'tabler:chevron-right',
  'chevron-up': 'tabler:chevron-up',
  'chevron-down': 'tabler:chevron-down',
  fingerprint: 'fa-solid:fingerprint',
  suitcase: 'majesticons:suitcase',
  fire: 'mdi:fire',
  wand: 'mdi:wand',
  'degree-hat': 'icon-park-solid:degree-hat',
  linkedin: 'mdi:linkedin',
  google: 'mdi:google',
  home: 'mdi:home',
  profile: 'tabler:user',
  message: 'tabler:message-2-star',
  settings: 'mdi:settings',
  logout: 'mdi:logout',
  'horizontal-dots': 'mdi:dots-horizontal',
  close: 'mdi:close',
  french: 'emojione:flag-for-france',
  english: 'emojione:flag-for-united-kingdom',
  dutch: 'emojione:flag-for-netherlands',
  belgium: 'emojione:flag-for-belgium',
  morocco: 'emojione:flag-for-morocco',
  external: 'mdi:external-link',
  'right-arrow': 'mdi:arrow-right',
  'heart-broken': 'mdi:heart-broken',
  search: 'mdi:magnify',
  filter: 'mdi:filter',
  star: 'mdi:star',
  'personality-result': 'tabler:dots-diagonal',
  'attention-points': 'mdi:eye',
  book: 'tabler:book',
  radio: 'formkit:radio',
  'tip-icon': 'mdi:comment-warning',
  info: 'ic:round-info',
  'arrow-up': 'ph:arrow-up',
  tip: 'ic:baseline-tips-and-updates',
  play: 'ph:play-fill',
  'finish-flag': 'icons8:finish-flag',
  check: 'mdi:check',
  rocket: 'tabler:rocket',
  puzzle: 'mdi:puzzle',
  users: 'mdi:users',
  bug: 'tabler:bug',
  bulb: 'tabler:bulb',
  'general-feedback': 'mdi:chat-outline',
  support: 'ic:baseline-support',
  'role-link-icon': 'ph:share',
  'organizations-icon': 'octicon:organization-24',
  delete: 'mdi:delete',
  edit: 'tabler:edit',
  'my-students-icon': 'mdi:account-student-outline',
  like: 'mdi:like',
  dislike: 'mdi:dislike',
  'classes-icon': 'streamline:class-lesson',
  'career-orientation-icon': 'mdi:briefcase-outline',
  'training-icon': 'ic:outline-model-training',
  'certifications-icon': 'ph:certificate',
} as const;
