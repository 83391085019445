import { type ToolboxResponse } from 'services/api';
import { Icon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

import { Tile } from './Tile';

export const MentoringTile = ({
  networkingMentoring,
}: {
  networkingMentoring: ToolboxResponse['networkingMentoring'];
}) => {
  const t = useTranslate();

  return (
    <Tile title={networkingMentoring.title} icon={networkingMentoring.logo}>
      <div className="mb-8 flex flex-col items-center">
        <Icon altName={networkingMentoring.innerLogo1!} className="text-brand-blue text-5xl" />
        <div className="mb-4 mt-4 text-center font-bold">
          {t('orientation.toolbox.networking_mentoring.networking')}
        </div>
        <p className="prose prose-sm text-primary whitespace-pre-line text-center">
          {networkingMentoring.networking.localizedNetworkingDescription}
        </p>
      </div>

      <div className="flex flex-col items-center">
        <Icon altName={networkingMentoring.innerLogo2!} className="text-brand-blue text-5xl" />
        <div className="mb-4 mt-4 text-center font-bold">{t('orientation.toolbox.networking_mentoring.mentoring')}</div>
        <p className="prose prose-sm text-primary whitespace-pre-line text-center">
          {networkingMentoring.mentoring.localizedMentoringDescription}
        </p>
      </div>
    </Tile>
  );
};
