import { Heading, PageLoader } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { CAREER_ORIENTATION_PAGE } from 'consts/pages';
import { usePersonalitySurveyResults } from 'services/api';

import { PersonalityResults } from 'components/PersonalityResults';

export const PersonalitySurveyResultsPage = () => {
  const t = useTranslate();
  const personalitySurveyResultsQuery = usePersonalitySurveyResults();

  if (personalitySurveyResultsQuery.isLoading || !personalitySurveyResultsQuery.data) {
    return <PageLoader />;
  }

  return (
    <div className="pb-14">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.career_orientation',
            href: CAREER_ORIENTATION_PAGE.path,
          },
          {
            label: 'breadcrumbs.personality_survey_results',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading type="h1" iconName="puzzle" className="mb-2">
        {t('orientation.personality_survey.results.your_personality.title')}
      </Heading>

      <PersonalityResults data={personalitySurveyResultsQuery.data} />
    </div>
  );
};
