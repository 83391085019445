import { Fragment, type ReactNode } from 'react';
import { useTranslate } from '@interskillar/localization';
import { type ToolboxResponse } from 'services/api';

import { OrganizationTile } from './OrganizationTile';
import { WorkEnvironments } from './WorkEnvironments';
import { PersonalProjectsTile } from './PersonalProjectsTile';
import { MentoringTile } from './MentoringTile';
import { PersonalDevelopmentTile } from './PersonalDevelopmentTile';
import { TipsTile } from './TipsTile';
import { MarketInsights } from './MarketInsights';

export const ToolboxResults = ({ data }: { data: ToolboxResponse }) => {
  const t = useTranslate();

  return (
    <div className="max-w-xl pb-14">
      <p className="prose text-primary mb-4">{t('orientation.toolbox.description')}</p>

      <div className="grid grid-cols-1 gap-4">
        {(
          [
            [data.organizations.order, <OrganizationTile organizations={data.organizations} />],
            [data.workEnvironments.order, <WorkEnvironments workEnvironments={data.workEnvironments} />],
            [data.marketInsights.order, <MarketInsights marketInsights={data.marketInsights} />],
            [data.personalProjects.order, <PersonalProjectsTile personalProjects={data.personalProjects} />],
            [data.networkingMentoring.order, <MentoringTile networkingMentoring={data.networkingMentoring} />],
            [
              data.personalDevelopment.order,
              <PersonalDevelopmentTile personalDevelopment={data.personalDevelopment} />,
            ],
            [data.tips.order, <TipsTile tips={data.tips} />],
          ] as [number, ReactNode][]
        )
          .sort((a, b) => a[0] - b[0])
          .map(([idx, content]) => (
            <Fragment key={idx}>{content}</Fragment>
          ))}
      </div>
    </div>
  );
};
