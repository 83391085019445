import { useMediaQuery } from 'utils/hooks';

import { DesktopNavigation } from './DesktopNavigation';
import { MobileNavigation } from './MobileNavigation';

export const Navigation = ({
  isDesktopMenuExpanded,
  toggleIsDesktopMenuExpanded,
}: {
  isDesktopMenuExpanded: boolean;
  toggleIsDesktopMenuExpanded: () => void;
}) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return isMobile ? (
    <MobileNavigation />
  ) : (
    <DesktopNavigation
      isDesktopMenuExpanded={isDesktopMenuExpanded}
      toggleIsDesktopMenuExpanded={toggleIsDesktopMenuExpanded}
    />
  );
};
