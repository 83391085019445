import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';
import { withArtificialDelay } from 'utils/promises';

import { isHTTPError, useApi } from './api';
import { paths } from './types';

type ExperienceAssessmentResponseBody =
  paths['/api/experience-assessment']['get']['responses']['200']['content']['application/json'];

export const useExperienceSurvey = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['experienceSurvey'],
    queryFn: async () => {
      try {
        const response = await api!.get('experience-assessment');

        return response.json<ExperienceAssessmentResponseBody>();
      } catch (error) {
        if (isHTTPError(error)) {
          if (error.response.status === 404) {
            return null;
          }
        }

        throw error;
      }
    },
    enabled: !!api,
  });
};

type ExperienceAssessmentOptions =
  paths['/api/experience-assessment/options']['get']['responses']['200']['content']['application/json'];

export const useExperienceSurveyOptions = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['experienceSurveyOptions', mappedLanguage],
    queryFn: async () => {
      const response = await api!.get('experience-assessment/options', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<ExperienceAssessmentOptions>();
    },
    enabled: !!api,
  });
};

type UpdateExperienceSurveyRequestBody =
  paths['/api/experience-assessment']['post']['requestBody']['content']['application/json'];
type UpdateExperienceSurveyResponseBody =
  paths['/api/experience-assessment']['post']['responses']['200']['content']['application/json'];

export const useUpdateExperienceSurvey = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateExperienceSurveyRequestBody) => {
      const response = await withArtificialDelay(
        api!.post('experience-assessment', {
          json: data,
        }),
      );

      return response.json<UpdateExperienceSurveyResponseBody>();
    },
    onSuccess: async (data) => {
      queryClient.setQueryData(['experienceSurvey'], data);
      await queryClient.invalidateQueries(['assessmentsStatus']);
      await queryClient.invalidateQueries(['onboardingStatus']);
    },
  });
};
