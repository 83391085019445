import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Heading,
  Label,
  Button,
  Separator,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  Input,
  FormMessage,
  Form,
  FormDescription,
  RadioGroup,
  RadioGroupItem,
} from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import {
  type DiversityAssessment,
  type UpdateDiversityAssessmentBody,
  useDiversityAssessment,
  useDiversityAssessmentCodebooks,
  useUpdateDiversityAssessment,
} from 'services/api';

import { ControlledSelect } from './ControlledSelect';
import { atLeastOneMessage, validationSchema } from './validationSchema';

type FieldValues = DiversityAssessment;

export const ProfileForm = () => {
  const t = useTranslate();
  const codebooksQuery = useDiversityAssessmentCodebooks();
  const diversityAssessmentQuery = useDiversityAssessment();
  const updateDiversityAssement = useUpdateDiversityAssessment();

  const form = useForm<FieldValues>({
    defaultValues: diversityAssessmentQuery.data,
    resolver: zodResolver(validationSchema),
    shouldFocusError: false,
  });

  const limitationsInDailyActivitiesId = form.watch('limitationInDailyActivitiesId');

  useEffect(() => {
    const fields: (keyof FieldValues)[] = [
      'genderId',
      'ethnicityIds',
      'sexualOrientationId',
      'educationLevelId',
      'fieldOfStudyIds',
      'age',
      'limitationInDailyActivitiesId',
      'postalCode',
      'relationshipStatusId',
      'familyStatusId',
    ];

    for (const field of fields) {
      if (form.formState.errors[field]?.ref) {
        const el = form.formState.errors[field]?.ref as HTMLInputElement;
        if (!el) {
          continue;
        }

        if (el.focus) {
          el.focus();
        } else {
          const element = document.getElementsByName(field)[0];

          if (element && element instanceof HTMLInputElement) {
            element.focus();
          }
        }

        break;
      }
    }
  }, [form.formState.errors]);

  if (codebooksQuery.data == null) {
    return null;
  }

  const isLimitedInDailyActivities = codebooksQuery.data.limitationsInDailyActivities.find(
    (limitation) => limitation.id === Number(limitationsInDailyActivitiesId),
  )?.isAffirmative;

  const handleFormSubmit = (data: UpdateDiversityAssessmentBody) => {
    if (isLimitedInDailyActivities && !data.disabilityIds?.length) {
      form.setError(
        'disabilityIds',
        {
          message: atLeastOneMessage,
        },
        {
          shouldFocus: true,
        },
      );

      return;
    }

    updateDiversityAssement
      .mutateAsync(data)
      .then(() => {
        toast.success(t('profile.update_success_message'));
      })
      .catch(() => {
        toast.error(t('profile.update_error_message'));
      });
  };

  return (
    <>
      <Heading type="h2" font="body" className="mb-4" weight="medium">
        {t('profile.demographics_subtitle')}
      </Heading>

      <Form {...form}>
        <form className="space-y-6" onSubmit={form.handleSubmit(handleFormSubmit)}>
          <ControlledSelect
            tabIndex={1}
            dataTestId="gender-select"
            label={t('profile.gender')}
            name="genderId"
            id="gender"
            control={form.control}
            placeholder={t('profile.gender_placeholder')}
            options={codebooksQuery.data.genders.map((g) => ({ label: t(g.text), value: g.id }))}
          />

          <ControlledSelect
            tabIndex={2}
            dataTestId="ethnicity-select"
            isMulti
            id="ethnicity-select"
            label={t('profile.ethnic_background')}
            placeholder={t('profile.ethnic_background_placeholder')}
            name="ethnicityIds"
            control={form.control}
            options={codebooksQuery.data.ethnicities.map((g) => ({ label: t(g.text), value: g.id }))}
            limit={4}
            info={t('profile.ethnic_background_info')}
          />

          <ControlledSelect
            tabIndex={3}
            dataTestId="sexual-orientation-select"
            id="sexual-orientation-select"
            label={t('profile.sexual_orientation')}
            placeholder={t('profile.sexual_orientation_placeholder')}
            name="sexualOrientationId"
            control={form.control}
            options={codebooksQuery.data.sexualOrientations.map((g) => ({ label: t(g.text), value: g.id }))}
          />

          <ControlledSelect
            tabIndex={4}
            dataTestId="education-level-select"
            id="education-level-select"
            label={t('profile.education_level')}
            placeholder={t('profile.education_level_placeholder')}
            name="educationLevelId"
            control={form.control}
            options={codebooksQuery.data.educationLevels.map((g) => ({ label: t(g.text), value: g.id }))}
          />

          <ControlledSelect
            tabIndex={5}
            dataTestId="fields-of-study-select"
            id="fields-of-study-select"
            isMulti
            limit={3}
            label={t('profile.fields_of_study')}
            placeholder={t('profile.fields_of_study_placeholder')}
            info={t('profile.fields_of_study_info')}
            name="fieldOfStudyIds"
            control={form.control}
            options={codebooksQuery.data.fieldsOfStudy.map((g) => ({
              label: t(g.text),
              options: g.items.map((item) => ({
                label: t(item.text),
                value: item.id,
              })),
            }))}
          />

          <FormField
            control={form.control}
            name="age"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t('profile.age')}</FormLabel>
                  <FormControl>
                    <Input
                      min={18}
                      max={99}
                      tabIndex={6}
                      type="number"
                      data-testid="age-input"
                      placeholder={t('profile.age_placeholder')}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormDescription>{t('profile.age_info')}</FormDescription>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="postalCode"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t('profile.postal_code')}</FormLabel>
                  <FormControl>
                    <Input
                      tabIndex={7}
                      type="number"
                      data-testid="postal-code-input"
                      placeholder={t('profile.postal_code_placeholder')}
                      {...field}
                      value={field.value || undefined}
                    />
                  </FormControl>
                  <FormDescription>{t('profile.postal_code_info')}</FormDescription>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <Separator />

          <div>
            <FormField
              control={form.control}
              name="limitationInDailyActivitiesId"
              render={({ field }) => {
                return (
                  <FormItem className="space-y-2">
                    <Label className="text-lg">{t('profile.disability_form_subtitle')}</Label>
                    <FormLabel>{t('profile.disability_subtitle')}</FormLabel>

                    <FormControl className="mt-4">
                      <RadioGroup
                        tabIndex={8}
                        onValueChange={field.onChange}
                        defaultValue={field.value ? String(field.value) : undefined}
                      >
                        {codebooksQuery.data.limitationsInDailyActivities.map((limitation, i) => (
                          <FormItem key={limitation.id}>
                            <FormControl>
                              <RadioGroupItem data-testid={`limitation-option-${i}`} value={String(limitation.id)} />
                            </FormControl>
                            <FormLabel className="ms-2">{t(limitation.text)}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {isLimitedInDailyActivities && (
              <div className="mt-4">
                <ControlledSelect
                  tabIndex={9}
                  dataTestId="disabilities-select"
                  id="disabilities-select"
                  isMulti
                  name="disabilityIds"
                  control={form.control}
                  info={t('profile.disabilities_input_info')}
                  placeholder={t('profile.disabilities_input_placeholder')}
                  options={codebooksQuery.data.disabilities.map((disability) => ({
                    label: t(disability.text),
                    value: disability.id,
                  }))}
                />
              </div>
            )}
          </div>

          <div>
            <Separator />

            <Label className="mt-5 text-lg">{t('profile.relationship_family_status_subtitle')}</Label>

            <p className="mb-4">{t('profile.relationship_family_status_description')}</p>

            <FormField
              control={form.control}
              name="relationshipStatusId"
              render={({ field }) => {
                return (
                  <FormItem className="mb-4">
                    <FormLabel>{t('profile.relationship')}</FormLabel>

                    <FormControl>
                      <RadioGroup
                        tabIndex={10}
                        onValueChange={field.onChange}
                        defaultValue={field.value ? String(field.value) : undefined}
                      >
                        {codebooksQuery.data.relationshipStatuses.map((relationshipStatus, i) => (
                          <FormItem key={relationshipStatus.id}>
                            <FormControl>
                              <RadioGroupItem
                                data-testid={`relationship-status-${i}`}
                                value={String(relationshipStatus.id)}
                              />
                            </FormControl>
                            <FormLabel className="ms-2">{t(relationshipStatus.text)}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="familyStatusId"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>{t('profile.family')}</FormLabel>
                    <FormControl>
                      <RadioGroup
                        tabIndex={11}
                        onValueChange={field.onChange}
                        defaultValue={field.value ? String(field.value) : undefined}
                      >
                        {codebooksQuery.data.familyStatuses.map((familyStatus, i) => (
                          <FormItem key={familyStatus.id}>
                            <FormControl>
                              <RadioGroupItem data-testid={`family-status-${i}`} value={String(familyStatus.id)} />
                            </FormControl>
                            <FormLabel className="ms-2">{t(familyStatus.text)}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage data-testid="family-status-error" />
                  </FormItem>
                );
              }}
            />
          </div>
          <div className="mt-8 flex justify-end">
            <Button
              variant="green"
              type="submit"
              data-testid="submit-button"
              tabIndex={12}
              disabled={!form.formState.isDirty}
              isLoading={updateDiversityAssement.isLoading}
            >
              {t('profile.submit_button_text')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
