import { useState } from 'react';
import clsx from 'clsx';

import { type ToolboxResponse } from 'services/api';

import { Tile } from './Tile';
import { ScrollableIconSection } from './ScrollableIconSection';

export const MarketInsights = ({ marketInsights }: { marketInsights: ToolboxResponse['marketInsights'] }) => {
  const [index, setIndex] = useState(0);

  const realIndex = ((index % marketInsights.items.length) + marketInsights.items.length) % marketInsights.items.length;

  const currentMarket = marketInsights.items[realIndex];

  const longestItemIndex = marketInsights.items.reduce((acc, item, i) => {
    if (item.title.length > marketInsights.items[acc].title.length) {
      return i;
    }
    return acc;
  }, 0);

  return (
    <Tile title={marketInsights.title} icon={marketInsights.logo}>
      <ScrollableIconSection onBack={() => setIndex((x) => x - 1)} onForward={() => setIndex((x) => x + 1)}>
        <div className="relative flex flex-col">
          {marketInsights.items.map((item, i) => (
            <div
              key={i}
              className={clsx(
                'flex h-full w-full items-center justify-center text-center font-bold',
                i === longestItemIndex ? 'relative' : 'absolute',
                i === realIndex ? 'visible opacity-100' : 'invisible opacity-0',
              )}
            >
              {item.title}
            </div>
          ))}
        </div>
      </ScrollableIconSection>

      <p className="prose text-primary prose-sm mt-2 text-center">{currentMarket.text}</p>
    </Tile>
  );
};
