import { Separator } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { type PersonalitySurveyResults } from 'services/api';

import { YourPersonality } from './components/YourPersonality';
import { AttentionPoints } from './components/AttentionPoints';
import { LearningStyle } from './components/LearningStyle';

export const PersonalityResults = ({ data }: { data: PersonalitySurveyResults }) => {
  const t = useTranslate();

  return (
    <>
      <p className="prose text-primary mb-6">{t('orientation.personality_survey.results.your_personality.subtitle')}</p>

      <YourPersonality
        decisionMaking={data.decisionMaking}
        strengths={data.strengths}
        professionalBehaviors={data.professionalBehaviors}
        communications={data.communications}
        graphIntroduction={data.localizedPersonalityGraphIntroduction}
      />

      <Separator className="!mb-3 !mt-6" />

      <AttentionPoints attentionPoints={data.attentionPoints} />

      <Separator className="!mb-3 !mt-6" />

      <LearningStyle learningStyle={data.learningStyle} />
    </>
  );
};
