import * as React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { getInitialLanguage } from './utils';
import { useTranslations } from './useTranslations';
import { DEFAULT_LANGUAGE, languagesImportMap, AvailableLanguages } from './consts';

const initialLanguage = getInitialLanguage();

type Context = {
  language: AvailableLanguages;
  direction: 'ltr' | 'rtl';
  mappedLanguage: string;
  changeLanguage: (language: AvailableLanguages) => void;
};
const noop = () => void 0;

const LANGUAGE_CODE_MAPPING: Record<AvailableLanguages, string> = {
  en: 'en',
  'fr-BE': 'fr',
  'nl-BE': 'nl',
  // TODO change to ar when arabic translations are available
  'ar-MA': 'ar-MA',
};

const LANGUATE_DIRECTION_MAPPING: Record<AvailableLanguages, 'ltr' | 'rtl'> = {
  en: 'ltr',
  'fr-BE': 'ltr',
  'nl-BE': 'ltr',
  'ar-MA': 'rtl',
};

export const IntlState = React.createContext<Context>({
  language: initialLanguage,
  direction: LANGUATE_DIRECTION_MAPPING[initialLanguage],
  mappedLanguage: LANGUAGE_CODE_MAPPING[initialLanguage],
  changeLanguage: noop,
});

export type IntlProviderProps = {
  children: React.ReactNode;
};

export const IntlProvider = ({ children }: IntlProviderProps) => {
  const [language, setLanguage] = React.useState(initialLanguage);

  const translationsQuery = useTranslations(language);

  const changeLanguage = React.useCallback((language: AvailableLanguages) => {
    let lang: AvailableLanguages = DEFAULT_LANGUAGE;
    if (!languagesImportMap[language]) {
      if (import.meta.env.DEV) {
        throw new Error(`Language "${language}" is not supported`);
      }

      lang = DEFAULT_LANGUAGE;
    } else {
      lang = language;
    }

    setLanguage(lang);
    localStorage.setItem('lang', lang);
  }, []);

  const ctxValue = React.useMemo(() => {
    return {
      language,
      direction: LANGUATE_DIRECTION_MAPPING[language],
      mappedLanguage: LANGUAGE_CODE_MAPPING[language],
      changeLanguage,
    };
  }, [language, changeLanguage]);

  React.useEffect(() => {
    document.documentElement.lang = language;
    document.documentElement.dir = LANGUATE_DIRECTION_MAPPING[language];
  }, [language]);

  return (
    <IntlState.Provider value={ctxValue}>
      <ReactIntlProvider
        messages={translationsQuery.data ?? {}}
        locale={initialLanguage}
        defaultLocale={DEFAULT_LANGUAGE}
      >
        {children}
      </ReactIntlProvider>
    </IntlState.Provider>
  );
};
