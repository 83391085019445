import { useQuery } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';

import { paths, useApi } from './api';

export type ToolboxResponse =
  paths['/api/assessment-outputs/toolbox']['get']['responses']['200']['content']['application/json'];

export const useToolbox = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['results', 'toolbox', mappedLanguage],
    queryFn: async () => {
      const response = await api!.get('assessment-outputs/toolbox', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<ToolboxResponse>();
    },
    enabled: !!api,
  });
};
