import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';

import { SETTINGS_PAGE } from 'consts/pages';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useTranslate } from '@interskillar/localization';
import {
  Heading,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  Input,
  FormMessage,
  Button,
} from '@interskillar/ui';
import { isHTTPError, parseHTTPError, useChangePassword } from 'services/api';

const requiredField = z
  .string({
    required_error: 'validation.required_field',
  })
  .min(1, {
    message: 'validation.required_field',
  });

const validationSchema = z
  .object({
    oldPassword: requiredField,
    newPassword: requiredField,
    confirmPassword: requiredField,
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'validation.passwords_dont_match',
    path: ['confirmPassword'],
  })
  .refine((data) => data.newPassword !== data.oldPassword, {
    message: 'validation.same_passwords',
    path: ['newPassword'],
  });

type FormValues = z.infer<typeof validationSchema>;

export const ChangePasswordPage = () => {
  const t = useTranslate();
  const changePasswordMutation = useChangePassword();
  const form = useForm<FormValues>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: zodResolver(validationSchema),
  });

  const handleFormSubmit = (data: FormValues) => {
    changePasswordMutation
      .mutateAsync(data)
      .then(() => {
        toast.success(t('settings.change_password.success_message'));
      })
      .catch(async (error) => {
        if (isHTTPError(error)) {
          const err = await parseHTTPError<{ errors: Record<keyof FormValues, string[]> }>(error);

          for (const el of Object.keys(err.errors)) {
            const castedEl = el as keyof FormValues;
            form.setError(castedEl, {
              message: err.errors[castedEl][0],
            });
          }
        }
      });
  };

  return (
    <div className="pb-14">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.settings',
            href: SETTINGS_PAGE.path,
          },
          {
            label: 'breadcrumbs.change_password',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading iconName="settings" className="mb-4">
        {t('settings.change_password.title')}
      </Heading>

      <Form {...form}>
        <form className="max-w-sm space-y-4" onSubmit={form.handleSubmit(handleFormSubmit)}>
          <FormField
            control={form.control}
            name="oldPassword"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.change_password.current_password_label')}</FormLabel>
                  <FormControl>
                    <Input id="current-password-input" autoComplete="current-password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="newPassword"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.change_password.new_password_label')}</FormLabel>
                  <FormControl>
                    <Input id="new-password-input" autoComplete="new-password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.change_password.confirm_password_label')}</FormLabel>
                  <FormControl>
                    <Input id="confirm-password-input" autoComplete="new-password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className="flex justify-end pt-2">
            <Button type="submit" disabled={!form.formState.isDirty} isLoading={changePasswordMutation.isLoading}>
              {t('settings.change_password.submit_button')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
