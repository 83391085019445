import { type ComponentProps, forwardRef, type ReactNode } from 'react';

import { Input } from './Input';
import { NewLabel } from './NewLabel';

type InputWithLabelProps = ComponentProps<typeof Input> & {
  id: string;
  label: string;
  error?: ReactNode;
  info?: ReactNode;
};

export const InputWithLabel = forwardRef<HTMLInputElement, InputWithLabelProps>(
  ({ id, label, error, info, ...rest }, ref) => {
    return (
      <div className="grid w-full items-center gap-1.5">
        <NewLabel htmlFor={id}>{label}</NewLabel>

        <div>
          <Input id={id} ref={ref} {...rest} />

          {error ? <p className="text-sm text-red-500">{error}</p> : null}
          {info ? <p className="text-subtle text-sm">{info}</p> : null}
        </div>
      </div>
    );
  },
);
