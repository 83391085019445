import { Stepper } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

type SurveyStepsProps = {
  step: 0 | 1 | 2 | 3;
  initialAnimate?: boolean;
};

export const SurveySteps = ({ step, initialAnimate }: SurveyStepsProps) => {
  const t = useTranslate();

  return (
    <Stepper
      className="mb-4 max-w-xl"
      initialAnimate={initialAnimate}
      steps={[
        {
          title: t('orientation.steps.personality'),
          iconName: 'personality-result',
          isCompleted: step > 0,
          isCurrent: step === 0,
        },
        {
          title: t('orientation.steps.motivation'),
          iconName: 'rocket',
          isCompleted: step > 1,
          isCurrent: step === 1,
        },
        {
          title: t('orientation.steps.experience'),
          iconName: 'puzzle',
          isCompleted: step > 2,
          isCurrent: step === 2,
        },
      ]}
    />
  );
};
