import { useQuery } from '@tanstack/react-query';

import { type AvailableLanguages, languagesImportMap } from './consts';

export const useTranslations = (locale: AvailableLanguages) => {
  return useQuery({
    queryKey: ['translations', locale],
    queryFn: () => {
      return languagesImportMap[locale]();
    },
    keepPreviousData: true,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
