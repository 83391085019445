import { useRef, useState, useLayoutEffect, useCallback, useEffect } from 'react';
import type { RefObject } from 'react';

export function useToggle(initialValue = false) {
  const [on, setOn] = useState(initialValue);

  const handleToggle = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      return setOn(value);
    }

    return setOn((v) => !v);
  }, []);

  return [on, handleToggle] as const;
}

const FOCUSABLE_ELEMENT_SELECTORS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

interface FocusElements {
  first: HTMLElement | null;
  last: HTMLElement | null;
}

const manageFocus = (ref: RefObject<HTMLElement>, triggered: boolean): FocusElements => {
  if (triggered && ref.current) {
    const focusableElements = ref.current.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS);

    if (focusableElements.length > 0) {
      const firstFocusableEl = focusableElements[0] as HTMLElement | null;
      const lastFocusableEl = focusableElements[focusableElements.length - 1] as HTMLElement | null;

      return { first: firstFocusableEl, last: lastFocusableEl };
    }
  }

  return { first: null, last: null };
};

export const useFocusTrap = <T extends HTMLElement>(enabled = false) => {
  const ref = useRef<T | null>(null);

  const [triggered, setTriggered] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: desired
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const handleKeyDown = (ev: KeyboardEvent) => {
      // tab
      if (ev.keyCode === 9) {
        if (!triggered) {
          setTriggered(true);
        }

        const { first, last } = manageFocus(ref, triggered);

        if (ev.shiftKey && last && (document.activeElement === first || document.activeElement === ref.current)) {
          ev.preventDefault();
          last.focus();
        } else if (!ev.shiftKey && first && document.activeElement === last) {
          ev.preventDefault();
          first.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [triggered, ref, enabled]);

  return ref;
};

export function useLockBodyScroll(isActive = true) {
  useLayoutEffect(() => {
    if (isActive) {
      const tmp = document.body.dataset.lockStack;
      const newVal = tmp ? Number(tmp) + 1 : 1;
      document.body.dataset.lockStack = `${newVal}`;

      if (newVal > 0) {
        document.body.classList.add('scroll-lock');
      }
    }

    return () => {
      const tmp = document.body.dataset.lockStack;
      const newVal = tmp ? Number(tmp) - 1 : 0;
      document.body.dataset.lockStack = `${Math.max(newVal, 0)}`;

      if (newVal < 1) {
        document.body.classList.remove('scroll-lock');
      }
    };
  }, [isActive]);
}

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: desired
  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [query]);

  return matches;
}

export const PAGINATION_DOTS = '...';

export function usePagination(totalItems: number, itemsPerPage: number) {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPages: number = Math.ceil(totalItems / itemsPerPage);

  const nextPage = (): void => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = (): void => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToPage = (pageNumber: number): void => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getPageItems = <T>(items: T[]): T[] => {
    const startIndex: number = (currentPage - 1) * itemsPerPage;
    const endIndex: number = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };

  const getVisiblePageNumbers = (): (number | string)[] => {
    const current: number = currentPage;
    const last: number = totalPages;
    const delta: number = 2;
    const left: number = current - delta;
    const right: number = current + delta + 1;
    const range: (number | string)[] = [];
    const rangeWithDots: (number | string)[] = [];
    let l: number | undefined = undefined;

    for (let i: number = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l !== undefined) {
        if (typeof i === 'number' && typeof l === 'number') {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push(PAGINATION_DOTS);
          }
        }
      }
      rangeWithDots.push(i);
      // @ts-ignore
      l = i;
    }

    return rangeWithDots;
  };

  return {
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    goToPage,
    getPageItems,
    getVisiblePageNumbers,
  };
}
