import { useEffect, useState } from 'react';

export const useDebugIntl = () => {
  const [debug, setDebug] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.ctrlKey && e.shiftKey && e.key?.toLowerCase() === 'p') {
        setDebug((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return debug;
};
