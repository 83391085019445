import { toast } from 'react-toastify';
import { PendingInvitationsResponse, usePendingInvitations } from 'services/api';
import { useAcceptInviteToClass, useDeclineInviteToClass } from 'services/api';
import { Button, Card, CardContent, CardFooter, CardHeader, CardTitle, Spinner } from '@interskillar/ui';
import _delay from 'lodash/delay';
import { useTranslate } from '@interskillar/localization';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

const PendingInvitationCard = ({ data }: { data: PendingInvitationsResponse[number] }) => {
  const t = useTranslate();
  const acceptInviteMutation = useAcceptInviteToClass(data.organizationId, data.id);
  const declineInviteMutation = useDeclineInviteToClass(data.organizationId, data.id);

  const onAcceptSuccess = () => {
    toast.success(t('invitations.accept_success_message'));
    _delay(() => {
      window.location.reload();
    }, 1500);
  };
  const onDeclineSuccess = () => {
    toast.success(t('invitations.decline_success_message'));
    _delay(() => {
      window.location.reload();
    }, 1500);
  };

  const onError = () => {
    toast.error(t('invitations.accept_error_message'));
  };

  const handleAcceptInvite = () => {
    acceptInviteMutation.mutate(undefined, {
      onSuccess: onAcceptSuccess,
      onError,
    });
  };
  const handleDeclineInvite = () => {
    declineInviteMutation.mutate(undefined, {
      onSuccess: onDeclineSuccess,
      onError,
    });
  };

  return (
    <div className="from-brand-green via-brand-pink to-brand-blue background-animate max-w-[calc(400px+0.5rem)] rounded-2xl bg-gradient-to-br p-2">
      <Card className="max-w-[400px] rounded-xl !border-none">
        <CardHeader>
          <CardTitle className="text-center leading-snug tracking-wide">{t('invitations.title')}</CardTitle>
        </CardHeader>
        <CardContent>
          <FormattedMessage
            id="invitations.description"
            values={{
              b: (...chunks: ReactNode[]) => <b className="font-bold">{chunks}</b>,
              className: data.name,
            }}
          />
        </CardContent>
        <CardFooter className="justify-between">
          <Button
            variant="destructive"
            isLoading={declineInviteMutation.isLoading}
            disabled={acceptInviteMutation.isLoading || declineInviteMutation.isLoading}
            onClick={handleDeclineInvite}
          >
            {t('invitations.decline_button')}
          </Button>
          <Button
            variant="green"
            isLoading={acceptInviteMutation.isLoading}
            disabled={acceptInviteMutation.isLoading || declineInviteMutation.isLoading}
            onClick={handleAcceptInvite}
          >
            {t('invitations.accept_button')}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export const PendingInvitations = () => {
  const pendingInvitationsQuery = usePendingInvitations();

  if (pendingInvitationsQuery.isLoading) {
    return (
      <div className="mb-4 flex justify-center">
        <Spinner size="large" />
      </div>
    );
  }

  if (!pendingInvitationsQuery.data?.length) {
    return null;
  }

  return (
    <div className="mb-4">
      <PendingInvitationCard data={pendingInvitationsQuery.data[0]} />
    </div>
  );
};
