import { Icon } from '@interskillar/ui';

type IconDataCardProps = {
  icon: string;
  description: string;
};

export const IconDataCard = ({ icon, description }: IconDataCardProps) => {
  return (
    <div className="flex flex-col items-center">
      <Icon altName={icon} color="#4890e0" className="mb-2 text-5xl" />
      <p className="prose text-primary prose-sm text-center">{description}</p>
    </div>
  );
};
