import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';
import { withArtificialDelay } from 'utils/promises';

import { isHTTPError, useApi } from './api';
import { paths } from './types';

type CurrentMotivationSurveyResponseBody =
  paths['/api/motivation']['get']['responses']['200']['content']['application/json'];

export const useMotivationSurvey = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['motivationSurvey', mappedLanguage],
    queryFn: async () => {
      try {
        const response = await api!.get('motivation', {
          searchParams: new URLSearchParams({
            languageCode: mappedLanguage,
          }),
        });

        return response.json<CurrentMotivationSurveyResponseBody>();
      } catch (error) {
        if (isHTTPError(error)) {
          if (error.response.status === 404) {
            return null;
          }
        }

        throw error;
      }
    },
    enabled: !!api,
  });
};

type MotivationSurveyOptions =
  paths['/api/motivation/options']['get']['responses']['200']['content']['application/json'];

export const useMotivationSurveyOptions = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['motivationSurveyOptions', mappedLanguage],
    queryFn: async () => {
      const response = await api!.get('motivation/options', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<MotivationSurveyOptions>();
    },
    enabled: !!api,
  });
};

type UpdateMotivationSurveyRequestBody = paths['/api/motivation']['post']['requestBody']['content']['application/json'];
type UpdateMotivationSurveyResponseBody =
  paths['/api/motivation']['post']['responses']['200']['content']['application/json'];

export const useUpdateMotivationSurvey = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateMotivationSurveyRequestBody) => {
      const response = await withArtificialDelay(
        api!.post('motivation', {
          json: data,
        }),
      );

      return response.json<UpdateMotivationSurveyResponseBody>();
    },
    onSuccess: async (data) => {
      queryClient.setQueriesData(['motivationSurvey'], data);
      await queryClient.invalidateQueries(['assessmentsStatus']);
      await queryClient.invalidateQueries(['onboardingStatus']);
      await queryClient.invalidateQueries(['results']);
    },
  });
};
