import { useIntlState } from '@interskillar/localization';
import { Icon } from './Icon';

type RightIconProps = {
  className?: string;
  forceRight?: boolean;
};

export const RightIcon = ({ className, forceRight }: RightIconProps) => {
  const { direction } = useIntlState();

  return <Icon className={className} name={direction === 'rtl' && !forceRight ? 'chevron-left' : 'chevron-right'} />;
};
