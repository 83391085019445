import { useMutation } from '@tanstack/react-query';
import ky from 'ky';
import { type FeedbackType } from './FeedbackContext';

type FeedbackBody = {
  feedbackType: FeedbackType;
  feedbackMsg: string;
  feedbackEmail: string;
  feedbackSrc?: string;
};

export const useSendFeedback = () => {
  return useMutation({
    mutationFn: async (data: FeedbackBody) => {
      await ky.post('https://feeder-node-1337.herokuapp.com/feedback/create', {
        json: {
          projectId: '6564f2f7b3be400002c76a50',
          ...data,
        },
      });
    },
  });
};
