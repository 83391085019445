import { useState, type ReactNode } from 'react';

import { FeedbackContext, type FeedbackType } from './FeedbackContext';

type FeedbackProviderProps = {
  children: ReactNode;
};

export const FeedbackProvider = ({ children }: FeedbackProviderProps) => {
  const [selectedType, setSelectedType] = useState<FeedbackType | null>(null);

  return (
    <FeedbackContext.Provider
      value={{
        selectedType,
        setSelectedType,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
