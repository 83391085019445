import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageLoader, ProgressBar } from '@interskillar/ui';
import { setLatestSurvey } from 'utils/storage';
import { getStepProgress } from 'utils/surveys';
import { useMotivationSurvey, useMotivationSurveyOptions, useSectorsOfInterest, useUserProfile } from 'services/api';

import { SurveySteps } from '../../SurveySteps';
import { Motivation } from './Motivation';
import { SectorsOfInterest } from './SectorsOfInterest';

export const MotivationSurveyPage = () => {
  const hasSetStepRef = useRef(false);
  const location = useLocation();
  const userProfileQuery = useUserProfile();
  const motivationSurveyQuery = useMotivationSurvey();
  const sectorsOfInterestQuery = useSectorsOfInterest();
  const motivationSurveyOptionsQuery = useMotivationSurveyOptions();
  const [step, setStep] = useState<1 | 2>(1);

  const userId = userProfileQuery.data!.id;

  useEffect(() => {
    setLatestSurvey(userId, 'motivation');
  }, [userId]);

  useEffect(() => {
    if (!hasSetStepRef.current && motivationSurveyQuery.data && 'fromExperience' in (location.state || {})) {
      setStep(2);
      hasSetStepRef.current = true;
    }
  }, [location.state, motivationSurveyQuery.data]);

  if (
    motivationSurveyQuery.isLoading ||
    motivationSurveyOptionsQuery.isLoading ||
    !motivationSurveyOptionsQuery.data ||
    sectorsOfInterestQuery.isLoading
  ) {
    return <PageLoader />;
  }

  return (
    <div className="max-w-xl pb-14">
      <SurveySteps step={1} initialAnimate />

      <ProgressBar className="mb-4" value={step === 1 ? getStepProgress(1) : getStepProgress(2)} duration={500} />

      {step === 1 ? <Motivation onSuccess={() => setStep(2)} /> : <SectorsOfInterest onBack={() => setStep(1)} />}
    </div>
  );
};
