import { ReactNode } from 'react';

import { Button, Icon, LeftIcon, RightIcon } from '@interskillar/ui';

type ScrollableIconSectionProps = {
  icon?: string;
  onBack: () => void;
  onForward: () => void;
  children: ReactNode;
};

export const ScrollableIconSection = ({ icon, children, onBack, onForward }: ScrollableIconSectionProps) => {
  return (
    <div className="flex items-center justify-between">
      <Button variant="ghost" onClick={onBack}>
        <LeftIcon className="text-4xl" />
      </Button>

      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center">
          {icon && (
            <div className="min-h-[50px]">
              <Icon altName={icon} className="text-brand-blue text-5xl" />
            </div>
          )}
          <div className="">{children}</div>
        </div>
      </div>

      <Button variant="ghost" onClick={onForward}>
        <RightIcon className="text-4xl" />
      </Button>
    </div>
  );
};
