import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useDebugIntl } from './useDebugIntl';

export const useTranslate = () => {
  const intl = useIntl();
  const debug = useDebugIntl();

  return useCallback(
    (key: string, values?: Record<string, string | number>) => {
      if (debug) {
        return key;
      }
      return intl.formatMessage(
        {
          id: key,
        },
        values,
      );
    },
    [debug, intl],
  );
};
