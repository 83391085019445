import * as React from 'react';
import ReactSelect, { type GroupBase, type Props, type SelectInstance, type Options } from 'react-select';
import clsx from 'clsx';

import { Label } from './Label';
import './Select.scss';

export type SelectProps = {
  className?: string;
  label?: string;
  id?: string;
  info?: string;
  error?: string;
  dataTestId?: string;
  isRounded?: boolean;
  size?: 'sm' | 'md' | 'lg';
} & Props;

const mapValuesToData = (value: unknown = []) => {
  if (Array.isArray(value)) {
    return (value as Options<{ value: number }>).map((value) => value.value);
  }

  return (value as Options<{ value: number }>[number])?.value;
};

export const Select = React.forwardRef(
  (
    { className, isRounded = true, size = 'md', label, id, dataTestId, info, error, value, ...props }: SelectProps,
    ref: React.Ref<SelectInstance<unknown, boolean, GroupBase<unknown>>>,
  ) => {
    return (
      <div data-testid={dataTestId} data-test-selected={mapValuesToData(value)} className={className}>
        <Label htmlFor={id}>{label}</Label>

        <ReactSelect
          ref={ref}
          inputId={id}
          aria-label={label}
          classNamePrefix="rsmm"
          defaultValue={value}
          value={value}
          classNames={{
            control: () => clsx({ '!rounded-full': isRounded }),
            menu: () => clsx({ '!rounded-3xl': isRounded }),
            valueContainer: () =>
              clsx({
                'px-4 py-2': size === 'md',
                'px-4 py-0.5': size === 'sm',
              }),
            input: () => '!text-primary',
          }}
          {...props}
        />

        {info && !error && <p className="mt-1 h-4 text-sm text-gray-500">{info}</p>}
        {error && <p className="mt-1 h-4 text-sm text-red-600">{error}</p>}
      </div>
    );
  },
);
