import * as React from 'react';
import { Link as RRLink } from 'react-router-dom';
import cx from 'clsx';

import { Icon, Link } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

type Breadcrumbs = {
  breadcrumbs: (
    | {
        label: string;
        isCurrentPage: boolean;
      }
    | {
        label: string;
        href: string;
      }
  )[];
  className?: string;
};

export const Breadcrumbs = ({ breadcrumbs, className }: Breadcrumbs) => {
  const t = useTranslate();

  return (
    <nav className={cx(className, 'flex flex-wrap items-center gap-1 self-start')} aria-label="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;

        return (
          <React.Fragment key={breadcrumb.label}>
            {'href' in breadcrumb ? (
              <Link as={RRLink} className="text-subtle" type="secondary" to={breadcrumb.href}>
                {t(breadcrumb.label)}
              </Link>
            ) : (
              <span
                className="text-brand-pink font-bold"
                aria-current={breadcrumb.isCurrentPage ? 'location' : undefined}
              >
                {t(breadcrumb.label)}
              </span>
            )}

            {!isLast && <Icon name="chevron-right" className="text-subtle text-lg" aria-hidden="true" />}
          </React.Fragment>
        );
      })}
    </nav>
  );
};
