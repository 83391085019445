import React from 'react';
import { AnimatePresence, m } from 'framer-motion';

const motionProps = {
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  style: {
    overflow: 'hidden',
  },
  transition: {
    duration: 0.25,
    ease: 'easeInOut',
  },
};

export type CollapseProps = {
  isOpen: boolean;
  className?: string;
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

export const Collapse = ({ isOpen, className, fallback = null, children }: CollapseProps) => {
  return (
    <AnimatePresence initial={false}>
      {isOpen ? (
        <m.div key="content" initial="collapsed" animate="open" exit="collapsed" className={className} {...motionProps}>
          {children}
        </m.div>
      ) : (
        fallback
      )}
    </AnimatePresence>
  );
};
