import { useQuery } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';

import { paths, useApi } from './api';

export type TalentPassportResponse =
  paths['/api/assessment-outputs/talent-passport']['get']['responses']['200']['content']['application/json'];

export const useTalentPassport = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['results', 'talentPassport', mappedLanguage],
    queryFn: async () => {
      const response = await api!.get('assessment-outputs/talent-passport', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<TalentPassportResponse>();
    },
    enabled: !!api,
  });
};
