import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserProfile } from 'services/api';
import { MY_CLASSES_PAGE, SINGLE_ORGANIZATION_PAGE, USER_MANAGEMENT_PAGE } from 'consts/pages';
import { StudentHomePage } from './StudentHomePage';

export const HomePage = () => {
  const { data } = useUserProfile();

  if (!data) {
    return null;
  }

  if (data.roles.includes('ADMIN')) {
    return <Navigate to={USER_MANAGEMENT_PAGE.path} />;
  }

  if (data.roles.includes('PROGRAMMANAGER')) {
    return <Navigate to={SINGLE_ORGANIZATION_PAGE.getPath(data)} />;
  }

  if (data.roles.includes('STUDENT')) {
    return <StudentHomePage />;
  }

  if (data.roles.includes('COACH')) {
    return <Navigate to={MY_CLASSES_PAGE.path} />;
  }

  return null;
};
