import { createContext } from 'react';

export type FeedbackType = 'general' | 'bug' | 'idea';

type FeedbackContextType = {
  selectedType: FeedbackType | null;
  setSelectedType: (type: FeedbackType | null) => void;
};

export const FeedbackContext = createContext<FeedbackContextType>({
  selectedType: null,
  setSelectedType: () => {},
});
