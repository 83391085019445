import { DEFAULT_LANGUAGE, languages, AvailableLanguages } from './consts';

export function isLanguageAvailable(language: string): language is AvailableLanguages {
  return languages.includes(language as AvailableLanguages);
}

export const getInitialLanguage = (): (typeof languages)[number] => {
  const savedLanguage = localStorage.getItem('lang');

  if (!savedLanguage || !isLanguageAvailable(savedLanguage)) {
    return DEFAULT_LANGUAGE;
  }

  return savedLanguage;
};
