import cx from 'clsx';
import { useAuth } from 'oidc-react';
import { AnimatePresence, m } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icon, IconProps, Button, LeftIcon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { Authorize } from 'components/Authorize';
import { useUserProfile } from 'services/api';
import { useFeedbackModal } from 'services/feedback';
import { handleKeyDown } from 'utils/elements';
import { CLASS_STUDENT_DETAILS_PAGE, MY_CLASSES_PAGE, MY_STUDENTS_PAGE, SINGLE_ORGANIZATION_PAGE } from 'consts/pages';

import { LanguagePicker } from './LanguagePicker';
import { NAVIGATION_ITEMS } from './navigation.config';

type NavItemProps = {
  icon: IconProps['name'];
  label: string;
  className?: string;
  isComingSoon?: boolean;
  isActive?: boolean;
  dataTestId?: string;
  onClick: () => void;
  isDesktopMenuExpanded?: boolean;
};

const NavItem = ({
  icon,
  label,
  dataTestId,
  isComingSoon,
  isActive,
  onClick,
  className,
  isDesktopMenuExpanded = true,
}: NavItemProps) => {
  const handleClick = () => {
    if (isComingSoon) {
      return;
    }

    onClick();
  };

  return (
    <div
      tabIndex={isComingSoon ? -1 : 0}
      role="button"
      data-testid={dataTestId}
      onClick={handleClick}
      onKeyDown={handleKeyDown(handleClick)}
      className={cx(
        'relative flex w-full cursor-pointer flex-row items-center justify-start rounded-lg px-2 py-2',
        {
          'pointer-events-none': isComingSoon,
          'hover:from-navigation-accent/10 hover:to-navigation-accent/5 hover:bg-gradient-to-r':
            !isActive && !isComingSoon,
          'text-navigation-accent font-bold': isActive,
          'text-subtle opacity-50 grayscale': isComingSoon,
        },
        className,
      )}
    >
      <Icon name={icon} className="min-h-[1.5rem] min-w-[1.5rem] " />

      <m.div
        className="2xs:whitespace-nowrap ms-2 whitespace-normal text-center text-base leading-4"
        initial={false}
        animate={{
          opacity: isDesktopMenuExpanded ? 1 : 0,
        }}
      >
        {label}
      </m.div>
    </div>
  );
};

export const DesktopNavigation = ({
  isDesktopMenuExpanded,
  toggleIsDesktopMenuExpanded,
}: {
  isDesktopMenuExpanded: boolean;
  toggleIsDesktopMenuExpanded: () => void;
}) => {
  const { signOutRedirect } = useAuth();
  const t = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const userProfileQuery = useUserProfile();
  const { openFeedbackModal } = useFeedbackModal();

  const handleMenuItemClick = (to: string) => {
    navigate(to);
  };

  const handleLogout = async () => {
    await signOutRedirect();
  };

  return (
    <nav
      className={cx(
        'bg-brand sticky bottom-0 start-0 top-0 z-50 flex h-screen w-full flex-col items-start justify-start space-y-2  overflow-hidden p-4 shadow-lg shadow-gray-800 transition-all duration-300 ease-in-out',
        {
          'min-w-[285px] max-w-[285px]': isDesktopMenuExpanded,
          'min-w-[72px] max-w-[72px]': !isDesktopMenuExpanded,
        },
      )}
    >
      <div className="relative mb-4 mt-2 flex h-8 w-full items-center justify-between">
        <AnimatePresence>
          <m.div
            className="absolute"
            initial={{ opacity: Number(!isDesktopMenuExpanded) }}
            animate={{ opacity: Number(isDesktopMenuExpanded) }}
            exit={{ opacity: Number(!isDesktopMenuExpanded) }}
          >
            <img className="min-w-[12rem] max-w-[12rem]" src="/images/logotype.svg" />
          </m.div>
        </AnimatePresence>

        <AnimatePresence>
          <m.div
            className="absolute"
            initial={{ opacity: Number(isDesktopMenuExpanded) }}
            animate={{ opacity: Number(!isDesktopMenuExpanded) }}
            exit={{ opacity: Number(isDesktopMenuExpanded) }}
          >
            <img className="min-w-[3rem] max-w-[3rem]" src="/images/logo.svg" />
          </m.div>
        </AnimatePresence>
      </div>

      {NAVIGATION_ITEMS.map((item) => {
        if ('path' in item.page) {
          const path = item.page.path;

          let isActive: boolean;

          if (path === MY_CLASSES_PAGE.path) {
            if (userProfileQuery.data?.roles.includes('COACH')) {
              isActive =
                (location.pathname.startsWith('/organizations') &&
                  !(location.state as Record<string, boolean> | undefined)?.fromMyStudents) ||
                location.pathname === MY_CLASSES_PAGE.path;
            } else {
              isActive = path === MY_CLASSES_PAGE.path;
            }
          } else {
            isActive = path === '/' ? location.pathname === '/' : location.pathname.startsWith(path);
          }

          if (CLASS_STUDENT_DETAILS_PAGE.matchPath.test(location.pathname)) {
            if (
              item.page.path.startsWith('/organizations') &&
              (location.state as Record<string, boolean> | undefined)?.fromMyStudents
            ) {
              isActive = false;
            }

            if (
              item.page === MY_STUDENTS_PAGE &&
              (location.state as Record<string, boolean> | undefined)?.fromMyStudents
            ) {
              isActive = true;
            }
          }

          return (
            <Authorize key={item.page.menuLabelTKey} policy={item.policy}>
              <NavItem
                isComingSoon={item.isComingSoon}
                isActive={isActive}
                icon={item.page.iconName}
                dataTestId={`nav-item-${path}`}
                label={t(item.page.menuLabelTKey)}
                onClick={() => handleMenuItemClick(path)}
                isDesktopMenuExpanded={isDesktopMenuExpanded}
              />
            </Authorize>
          );
        }

        const path = item.page.getPath(userProfileQuery.data!);
        let isActive = path === '/' ? location.pathname === '/' : location.pathname.startsWith(path);

        if (CLASS_STUDENT_DETAILS_PAGE.matchPath.test(location.pathname)) {
          if (
            item.page === SINGLE_ORGANIZATION_PAGE &&
            (location.state as Record<string, boolean> | undefined)?.fromMyStudents
          ) {
            isActive = false;
          }
        }

        return (
          <Authorize key={item.page.menuLabelTKey} policy={item.policy}>
            <NavItem
              isComingSoon={item.isComingSoon}
              isActive={isActive}
              icon={item.page.iconName}
              dataTestId={`nav-item-${path}`}
              label={t(item.page.menuLabelTKey)}
              onClick={() => handleMenuItemClick(path)}
              isDesktopMenuExpanded={isDesktopMenuExpanded}
            />
          </Authorize>
        );
      })}

      <div className="flex w-full flex-1 flex-col space-y-2">
        <div className="flex-1" />

        <Button
          variant="ghost"
          className="-ms-2 w-max"
          onClick={() => toggleIsDesktopMenuExpanded()}
          aria-label={isDesktopMenuExpanded ? t('general.collapse') : t('general.expand')}
        >
          <LeftIcon
            className={cx('min-w-[1.5rem] transition-transform', {
              'rotate-180': !isDesktopMenuExpanded,
            })}
          />
        </Button>
        <NavItem
          isActive={false}
          dataTestId="nav-item-reach-out"
          icon="support"
          label={t('navigation.support')}
          onClick={() => openFeedbackModal('general')}
          isDesktopMenuExpanded={isDesktopMenuExpanded}
        />

        <LanguagePicker className="-ms-2 w-full" isDesktopMenuExpanded={isDesktopMenuExpanded} />

        <NavItem
          isActive={false}
          dataTestId="nav-item-logout"
          icon="logout"
          label={t('navigation.logout')}
          onClick={handleLogout}
          isDesktopMenuExpanded={isDesktopMenuExpanded}
        />
      </div>
    </nav>
  );
};
