import {
  useForm,
  // useFormContext
} from 'react-hook-form';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  // Heading,
  Button,
  // Switch,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  Input,
  FormMessage,
} from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { Authorize } from 'components/Authorize';
import { UserProfile, isHTTPError, parseHTTPError, useUpdateProfile } from 'services/api';

type FormValues = UserProfile['notificationSettings'];

export type FormProps = {
  profile: UserProfile;
};

const validationSchema = z.object({
  firstName: z.string().nonempty({ message: 'validation.required_field' }),
  lastName: z.string().nonempty({ message: 'validation.required_field' }),
  phoneNumber: z.string().optional().nullable(),
  jobPosition: z.string().optional().nullable(),
  updatesEnabled: z.boolean(),
  jobAlertsEnabled: z.boolean(),
  recommendationAlertsEnabled: z.boolean(),
});

// const SwitchItem = ({ label, name }: { label: string; name: keyof FormValues }) => {
//   const { control } = useFormContext<FormValues>();

//   return (
//     <FormField
//       control={control}
//       name={name}
//       render={({ field }) => {
//         return (
//           <div className="flex w-full items-center justify-between">
//             {label}
//             <Switch checked={field.value} onCheckedChange={field.onChange} />
//           </div>
//         );
//       }}
//     />
//   );
// };

export const AccountSettingsForm = ({ profile }: FormProps) => {
  const t = useTranslate();
  const updateProfileMutation = useUpdateProfile();

  const form = useForm({
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      phoneNumber: profile.phoneNumber,
      jobPosition: profile.jobPosition,
      updatesEnabled: profile.notificationSettings.updatesEnabled,
      jobAlertsEnabled: profile.notificationSettings.jobAlertsEnabled,
      recommendationAlertsEnabled: profile.notificationSettings.recommendationAlertsEnabled,
    },
    resolver: zodResolver(validationSchema),
  });

  const {
    setError,
    formState: { isDirty },
  } = form;

  const handleFormSubmit = (formData: FormValues) => {
    updateProfileMutation
      .mutateAsync({
        id: profile.id,
        ...formData,
      })
      .then(() => {
        toast.success(t('settings.updated_profile_success'));
      })
      .catch(async (error) => {
        if (isHTTPError(error)) {
          const err = await parseHTTPError<{ errors: Record<keyof FormValues, string[]> }>(error);

          for (const el of Object.keys(err.errors)) {
            const castedEl = el as keyof FormValues;
            setError(castedEl, {
              message: err.errors[castedEl][0],
            });
          }
        }

        toast.error(t('settings.updated_profile_error'));
      });
  };

  return (
    <Form {...form}>
      <form className="" onSubmit={form.handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col gap-2 md:flex-row">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.first_name_label')}</FormLabel>
                  <FormControl>
                    <Input id="first-name-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.last_name_label')}</FormLabel>
                  <FormControl>
                    <Input id="last-name-input" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>

        <Authorize
          policy={{
            anyPolicy: [
              'InterSkillar.Pages.AccountSettings.PhoneNumber',
              'InterSkillar.Pages.AccountSettings.JobPosition',
            ],
          }}
        >
          <div className="mt-2 flex flex-col gap-2 md:flex-row">
            <Authorize policy={{ singlePolicy: 'InterSkillar.Pages.AccountSettings.PhoneNumber' }}>
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => {
                  return (
                    <FormItem className="flex-1 md:max-w-[50%]">
                      <FormLabel>{t('settings.phone_number_label')}</FormLabel>
                      <FormControl>
                        <Input id="first-name-input" {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </Authorize>

            <Authorize policy={{ singlePolicy: 'InterSkillar.Pages.AccountSettings.JobPosition' }}>
              <FormField
                control={form.control}
                name="jobPosition"
                render={({ field }) => {
                  return (
                    <FormItem className="flex-1 md:max-w-[50%]">
                      <FormLabel>{t('settings.job_position_label')}</FormLabel>
                      <FormControl>
                        <Input id="last-name-input" {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </Authorize>
          </div>
        </Authorize>

        {/* <Heading type="h2" font="body" className="mb-4 mt-4">
          {t('settings.manage_notifications_subtitle')}
        </Heading>

        <div className="space-y-4">
          <SwitchItem label={t('settings.updates_label')} name="updatesEnabled" />
          <SwitchItem label={t('settings.recommendation_alerts')} name="recommendationAlertsEnabled" />
          <SwitchItem label={t('settings.job_alerts_label')} name="jobAlertsEnabled" />
        </div> */}

        <div className="!mt-8 flex justify-end">
          <Button
            type="submit"
            variant="green"
            className="w-full md:w-fit md:min-w-[100px]"
            isLoading={updateProfileMutation.isLoading}
            disabled={!isDirty}
          >
            {t('settings.save_button')}
          </Button>
        </div>
      </form>
    </Form>
  );
};
