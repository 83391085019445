import { useNavigate } from 'react-router-dom';

import { Heading, Button } from '@interskillar/ui';
import { FormattedMessage, useTranslate } from '@interskillar/localization';
import { PERSONALITY_SURVEY_PAGE } from 'consts/pages';

export const StartInstructions = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate(PERSONALITY_SURVEY_PAGE.path);
  };

  return (
    <div>
      <div className="mb-8 flex h-60 justify-center">
        <img src="https://i.giphy.com/media/imsP3HiNMHeY4eW00S/giphy.webp" className="w-60 rounded-xl" />
      </div>

      <Heading type="h2" className="mb-4 justify-center text-center" weight="bold">
        {t('orientation.start_instructions.title')}
      </Heading>

      <p className="prose prose-sm text-primary mb-8 text-center">
        <FormattedMessage
          id="orientation.start_instructions.description"
          values={{
            br: <br />,
          }}
        />
      </p>

      <div className="flex justify-center">
        <Button
          variant="green"
          className="w-full !rounded-full md:w-fit md:min-w-[100px] md:!rounded-lg"
          onClick={handleStartClick}
          data-testid="start-button"
        >
          {t('orientation.start_instructions.start_button')}
        </Button>
      </div>
    </div>
  );
};
