import cx from 'clsx';
import { m } from 'framer-motion';

import {
  Icon,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@interskillar/ui';
import { useIntlState, useTranslate } from '@interskillar/localization';

type LanguagePickerProps = {
  className?: string;
  isDesktopMenuExpanded?: boolean;
};

const CurrentLanguageIcon = ({ language, className }: { language: string; className: string }) => {
  if (language === 'nl-BE' || language === 'fr-BE') {
    return <Icon name="belgium" className={className} />;
  }

  if (language === 'ar-MA') {
    return <Icon name="morocco" className={className} />;
  }

  return <Icon name="english" className={className} />;
};

const CurrentLanguageText = ({ language }: { language: string }) => {
  const t = useTranslate();

  if (language === 'nl-BE') {
    return t('general.belgium_dutch');
  }

  if (language === 'fr-BE') {
    return t('general.belgium_french');
  }

  if (language === 'ar-MA') {
    return t('general.morocco_arabic');
  }

  return t('general.english');
};

export const LanguagePicker = ({ className, isDesktopMenuExpanded = true }: LanguagePickerProps) => {
  const { changeLanguage, language } = useIntlState();
  const t = useTranslate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className={className}>
          <Button variant="ghost">
            <CurrentLanguageIcon
              language={language}
              className={cx(
                'me-3.5 h-[30px] w-[30px] transition-all md:me-2.5 md:h-[22px] md:w-[22px]',
                isDesktopMenuExpanded ? undefined : 'me-0 delay-300',
              )}
            />

            <m.div
              className="text-2xl font-normal md:text-base"
              animate={{
                opacity: isDesktopMenuExpanded ? 1 : 0,
                width: isDesktopMenuExpanded ? 'auto' : 0,
              }}
            >
              <div>
                <CurrentLanguageText language={language} />
              </div>
            </m.div>
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem className="cursor-pointer py-2 text-lg md:text-base" onClick={() => changeLanguage('en')}>
          <Icon name="english" className="me-2.5 md:h-[22px] md:w-[22px]" />
          {t('general.english')}
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer py-2 text-lg" onClick={() => changeLanguage('fr-BE')}>
          <Icon name="belgium" className="me-2.5 md:h-[22px] md:w-[22px]" />
          {t('general.belgium_french')}
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer py-2 text-lg" onClick={() => changeLanguage('nl-BE')}>
          <Icon name="belgium" className="me-2.5 md:h-[22px] md:w-[22px]" />
          {t('general.belgium_dutch')}
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer py-2 text-lg" onClick={() => changeLanguage('ar-MA')}>
          <Icon name="morocco" className="me-2.5 md:h-[22px] md:w-[22px]" />
          {t('general.morocco_arabic')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
