import { useTranslate } from '@interskillar/localization';
import { Heading, PageLoader } from '@interskillar/ui';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { CAREER_ORIENTATION_PAGE } from 'consts/pages';
import { useTalentPassport } from 'services/api';

import { TalentPassportResults } from 'components/TalentPassportResults';

export const TalentPassportPage = () => {
  const t = useTranslate();
  const talentPassportQuery = useTalentPassport();

  if (talentPassportQuery.isLoading || !talentPassportQuery.data) {
    return <PageLoader />;
  }

  return (
    <div className="max-w-xl pb-14">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.career_orientation',
            href: CAREER_ORIENTATION_PAGE.path,
          },
          {
            label: 'breadcrumbs.talent_passport',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading iconName="suitcase" className="mb-4">
        {t('orientation.talent_passport.title')}
      </Heading>

      <TalentPassportResults data={talentPassportQuery.data} />
    </div>
  );
};
