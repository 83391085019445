import _groupby from 'lodash/groupBy';
import { AnimatePresence, m } from 'framer-motion';

import { Collapse, ProgressBar, Separator } from '@interskillar/ui';
import { FormattedMessage, useTranslate } from '@interskillar/localization';
import { useToggle } from 'utils/hooks';
import { ExpandButton } from 'components/ExpandButton';
import { type PersonalitySurveyResults } from 'services/api';

const COLORS = ['#F969FA', '#6788DB', '#7EF2B0', '#DBD767', '#FFA559'];

type CategoryItemProps = {
  name: string;
  color: string;
  data: PersonalitySurveyResults['professionalBehaviors'];
};

const CategoryItem = ({ name, color, data }: CategoryItemProps) => {
  const t = useTranslate();
  const [isOpen, toggleIsOpen] = useToggle();

  const categoryProgress =
    (data.reduce((acc, curr) => {
      return acc + curr.numericalValue;
    }, 0) /
      data.length) *
    10;

  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <div className="text-lg font-medium">{name}</div>

        <ExpandButton isOpen={isOpen} onToggle={toggleIsOpen} />
      </div>

      <div className="h-3">
        <AnimatePresence initial={false}>
          {!isOpen && (
            <m.div
              transition={{
                bounce: 0,
              }}
              initial={{
                y: 50,
                opacity: 0,
              }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
            >
              <div className="flex items-center">
                <div className="me-2 text-xs">
                  <FormattedMessage
                    id="orientation.personality_survey.results.your_personality.chart_left_label"
                    values={{
                      br: <br />,
                    }}
                  />
                </div>
                <div className="relative flex-1">
                  <div
                    className="absolute h-[2px] w-full bg-gray-200"
                    style={{
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                  {Array.from({ length: 10 }).map((_, index) => (
                    <div
                      key={index}
                      className="absolute h-[12px] w-[2px] bg-gray-200"
                      style={{
                        left: `${index * 11.111}%`,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                  ))}
                  <div
                    className="text-contrast absolute top-1/2 flex aspect-square w-max -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-2 bg-white p-1 text-xs"
                    style={{ borderColor: color, left: categoryProgress + '%' }}
                  >
                    {t('orientation.personality_survey.results.your_personality.you_label')}
                  </div>
                </div>
                <div className="ms-2 text-xs">
                  <FormattedMessage
                    id="orientation.personality_survey.results.your_personality.chart_right_label"
                    values={{
                      br: <br />,
                    }}
                  />
                </div>
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </div>

      <Collapse isOpen={isOpen}>
        <div className="mb-4 space-y-2">
          {data.map((behaviour) => (
            <div key={behaviour.id}>
              <div>{behaviour.localizedBehaviourAttributeName}</div>
              <ProgressBar
                value={behaviour.numericalValue * 10}
                color={color}
                ariaLabel={behaviour.localizedBehaviourAttributeName}
              />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export const PersonalityMetrics = ({
  professionalBehaviors,
}: {
  professionalBehaviors: PersonalitySurveyResults['professionalBehaviors'];
}) => {
  const grouped = _groupby(professionalBehaviors, (x) => x.localizedBehaviourName);

  return (
    <div className="mb-8 grid max-w-lg grid-cols-1 gap-1">
      {Object.keys(grouped).map((behaviourName, index) => (
        <div key={behaviourName}>
          <CategoryItem name={behaviourName} data={grouped[behaviourName]} color={COLORS[index % COLORS.length]} />
          <Separator className="!mt-7" />
        </div>
      ))}
    </div>
  );
};
