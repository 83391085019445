import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Heading,
  Icon,
  Button,
  LeftIcon,
  RightIcon,
} from '@interskillar/ui';
import { useIntlState, useTranslate } from '@interskillar/localization';
import { useMotivationSurvey, useMotivationSurveyOptions, useUpdateMotivationSurvey } from 'services/api';
import { PERSONALITY_SURVEY_PAGE } from 'consts/pages';

type MotivationItemProps = {
  id: number;
  title: string;
  description: string;
  isChecked: boolean;
  onChange: (id: number) => void;
};

const MotivationItem = ({ id, isChecked, title, description, onChange }: MotivationItemProps) => {
  const t = useTranslate();

  return (
    <div key={id} className="flex items-center" data-testid="motivation-option">
      <input
        name="motivation"
        onChange={() => onChange(id)}
        type="radio"
        checked={isChecked}
        tabIndex={0}
        id={id.toString()}
        value={id.toString()}
        className="accent-brand-yellow me-2 h-4 w-4"
      />

      <label className="w-full" htmlFor={id.toString()}>
        <div className="flex items-center">
          {title}
          <div>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="ghost" size="sm" aria-label={t('general.more_info')}>
                  <Icon name="info" className="text-brand-pink text-lg" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>{title}</DialogTitle>
                </DialogHeader>
                <p className="prose text-primary">{description}</p>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </label>
    </div>
  );
};

type MotivationProps = {
  onSuccess: () => void;
};

export const Motivation = ({ onSuccess }: MotivationProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { mappedLanguage } = useIntlState();
  const motivationSurveyQuery = useMotivationSurvey();
  const motivationSurveyOptionsQuery = useMotivationSurveyOptions();
  const updateMotivationSurveyMutation = useUpdateMotivationSurvey();
  const [selectedMotivationId, setSelectedMotivationId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const motivation = motivationSurveyQuery.data?.id || null;
    setSelectedMotivationId(motivation);
  }, [motivationSurveyQuery.data?.id]);

  const handleChangeMotivation = (motivationId: number) => {
    setError(null);
    setSelectedMotivationId(motivationId);
  };

  const handleSubmitMotivation = () => {
    if (!selectedMotivationId) {
      setError('validation.select_an_item');
      return;
    }

    updateMotivationSurveyMutation.mutate(
      {
        languageCode: mappedLanguage,
        motivationOptionId: selectedMotivationId!,
      },
      {
        onSuccess,
        onError: () => {
          toast.error(t('orientation.motivation_survey.error_message'));
        },
      },
    );
  };

  const handleBack = () => {
    navigate(PERSONALITY_SURVEY_PAGE.path, {
      replace: true,
      state: {
        fromMotivationSurvey: true,
      },
    });
  };

  return (
    <>
      <Heading isHighlighted font="body" className="mb-4 justify-center text-center">
        {t('orientation.motivation_survey.title')}
      </Heading>

      <p className="prose text-primary mb-2">{t('orientation.motivation_survey.description')}</p>

      <div className="space-y-2">
        {motivationSurveyOptionsQuery.data!.map((motivation) => {
          const isChecked = selectedMotivationId === motivation.id;

          return (
            <MotivationItem
              key={motivation.id}
              id={motivation.id}
              title={motivation.localizedTitle}
              description={motivation.localizedDescription}
              isChecked={isChecked}
              onChange={handleChangeMotivation}
            />
          );
        })}
      </div>

      <div className="mb-3 mt-3 h-7 text-red-600">{error && <span data-testid="error-message">{t(error)}</span>}</div>

      <div className="flex gap-2 md:justify-center">
        <Button
          className="w-full min-w-[100px] md:w-max"
          size="lg"
          variant="outline"
          data-testid="back-button"
          color="secondary"
          onClick={handleBack}
        >
          <LeftIcon className="me-2" />
          {t('general.back')}
        </Button>

        <Button
          className="w-full min-w-[100px] md:w-max"
          size="lg"
          variant="outline"
          data-testid="next-button"
          onClick={handleSubmitMotivation}
          isLoading={updateMotivationSurveyMutation.isLoading}
        >
          {t('general.next')}
          <RightIcon className="ms-2" />
        </Button>
      </div>
    </>
  );
};
