import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from './api';
import { paths } from './types';

type DiversityAssessmentCodebooks =
  paths['/api/diversity-assessment/codebooks']['get']['responses']['200']['content']['application/json'];

export const useDiversityAssessmentCodebooks = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['diversity-assessment/codebooks'],
    queryFn: async () => {
      const response = await api!.get('diversity-assessment/codebooks');

      return response.json<DiversityAssessmentCodebooks>();
    },
    enabled: !!api,
  });
};

export type DiversityAssessment =
  paths['/api/diversity-assessment']['get']['responses']['200']['content']['application/json'];

export const useDiversityAssessment = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['diversity-assessment'],
    queryFn: async () => {
      const response = await api!.get('diversity-assessment');

      return response.json<DiversityAssessment>();
    },
    enabled: !!api,
  });
};

type UpdateDiversityAssessmentResponse =
  paths['/api/diversity-assessment']['post']['responses']['200']['content']['application/json'];
export type UpdateDiversityAssessmentBody =
  paths['/api/diversity-assessment']['post']['requestBody']['content']['application/json'];

export const useUpdateDiversityAssessment = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateDiversityAssessmentBody) => {
      const response = await api!.post('diversity-assessment', {
        json: data,
      });

      return response.json<UpdateDiversityAssessmentResponse>();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['diversity-assessment'], data);
    },
  });
};
