import { ComponentProps } from 'react';
import { FormattedMessage as RIFormattedMessage } from 'react-intl';
import { useDebugIntl } from './useDebugIntl';

export const FormattedMessage = (props: ComponentProps<typeof RIFormattedMessage>) => {
  const debug = useDebugIntl();

  if (debug) {
    return <>{props.id}</>;
  }

  return <RIFormattedMessage {...props} />;
};
