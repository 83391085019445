import { type ReactNode } from 'react';
import clsx from 'clsx';
import { Icon, IconProps } from './Icon';

export type HeadingProps = {
  iconName?: IconProps['name'];
  isHighlighted?: boolean;
  className?: string;
  type?: 'h1' | 'h2' | 'h3';
  font?: 'heading' | 'body';
  weight?: 'bold' | 'medium';
  dataTestId?: string;
  children?: ReactNode;
};

export const Heading = ({
  iconName,
  isHighlighted,
  className,
  type = 'h1',
  font = type === 'h1' ? 'heading' : 'body',
  weight = type === 'h1' ? 'bold' : 'medium',
  children,
  dataTestId,
}: HeadingProps) => {
  const Component = type;

  return (
    <Component
      data-testid={dataTestId}
      className={clsx(
        'flex',
        isHighlighted && 'text-brand-yellow',
        {
          'text-3xl': type === 'h1',
          'text-2xl': type === 'h2',
          'text-xl': type === 'h3',
          'font-header text-brand-yellow uppercase': font === 'heading',
          'font-body': font === 'body',
          'font-bold': weight === 'bold',
          'font-medium': weight === 'medium',
        },
        className,
      )}
    >
      {iconName && (
        <Icon
          name={iconName}
          className={clsx('mt-1 min-w-[1.5rem]', {
            'me-2 text-3xl': type === 'h1',
            'me-1 text-2xl': type === 'h2',
            'me-1 text-xl': type === 'h3',
          })}
        />
      )}
      {children}
    </Component>
  );
};
