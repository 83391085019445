import { useMutation, useQueryClient } from '@tanstack/react-query';

import { withArtificialDelay } from 'utils/promises';

import { paths, useApi } from './api';

type ResetAssessmentResponse =
  paths['/api/assessments/reset']['delete']['responses'][200]['content']['application/json'];

export const useResetAssessments = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await withArtificialDelay(api!.delete('assessments/reset'), 1500);
      return response.json<ResetAssessmentResponse>();
    },
    onSuccess: async (data) => {
      queryClient.setQueriesData(['personalitySurveyStatus'], data);
      queryClient.setQueriesData(['sectorsOfInterest'], null);
      queryClient.setQueriesData(['motivationSurvey'], null);
      queryClient.setQueryData(['experienceSurvey'], null);
      await queryClient.invalidateQueries(['personalitySurveyQuestions']);
      await queryClient.invalidateQueries(['assessmentsStatus']);
      await queryClient.invalidateQueries(['onboardingStatus']);
      await queryClient.invalidateQueries(['results']);
    },
  });
};
