import { ReactNode, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { type TalentPassportResponse } from 'services/api';
import { handleKeyDown } from 'utils/elements';
import { Icon, Button } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

type MaxWidthTextProps = {
  text: string;
  x: number;
  y: number;
  maxWidth: number;
  lineHeight?: number;
  fontSize?: number;
  isSelected?: boolean;
  onClick: () => void;
};

const MaxWidthText = ({
  text,
  x,
  y,
  maxWidth,
  lineHeight = 11,
  fontSize = 10,
  isSelected,
  onClick,
}: MaxWidthTextProps) => {
  const words = text.split(' ');
  const tspans: ReactNode[] = [];
  let currentLine = '';
  let currentLineLength = 0;

  for (const word of words) {
    const wordLength = word.length * (fontSize * 0.6);

    if (currentLineLength + wordLength <= maxWidth) {
      currentLine += `${word} `;
      currentLineLength += wordLength;
    } else {
      tspans.push(
        <tspan x={x} dy={lineHeight} key={tspans.length}>
          {currentLine.trim()}
        </tspan>,
      );
      currentLine = `${word} `;
      currentLineLength = wordLength;
    }
  }

  tspans.push(
    <tspan x={x} dy={lineHeight} key={tspans.length}>
      {currentLine.trim()}
    </tspan>,
  );

  return (
    <text
      x={x - 5}
      y={y - 20}
      fontSize={fontSize}
      fontWeight={isSelected ? 'bold' : 'normal'}
      className="outline-none"
      fill="white"
      aria-selected={isSelected}
      textAnchor="middle"
      cursor="pointer"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown(onClick)}
    >
      {tspans}
    </text>
  );
};

type CustomLabelProps = {
  x: number;
  y: number;
  cx: number;
  cy: number;
  category: string;
  payload: { value: string; index: number };
  index: number;
  selectedIndex: number;
  onClick: (index: number) => void;
};

const CustomLabel = ({ x, y, cx, cy, payload, index, selectedIndex, onClick }: CustomLabelProps) => {
  const maxWidth = 100;
  const lineHeight = 12;
  const fontSize = 12;

  const isSelected = index === selectedIndex;

  return (
    <MaxWidthText
      y={y + (y - cy) / 2}
      x={x + (x - cx) / 2}
      text={`${payload.value}`}
      maxWidth={maxWidth}
      lineHeight={lineHeight}
      fontSize={fontSize}
      isSelected={isSelected}
      onClick={() => onClick(payload.index)}
    />
  );
};

const MAX_ITEMS = 8;

const ListWithTitle = ({
  icon,
  title,
  items,
}: {
  icon: ReactNode;
  title: string;
  items: { title: string; description?: string }[];
}) => {
  return (
    <div className="mb-4">
      <div className="mb-1 flex items-center gap-2 text-lg">
        {icon} {title}
      </div>
      <ul>
        {items.map((item) => (
          <li key={item.title} className="">
            <div className="flex items-start gap-3">
              <div className="border-primary ms-1 mt-1 min-h-[1rem] min-w-[1rem] rounded-full border-2" />
              {item.title}
            </div>
            {item.description && <div className="text-primary prose-sm mb-2 ms-8">{item.description}</div>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const RolesChart = ({ data }: { data: TalentPassportResponse }) => {
  const t = useTranslate();
  const [displayedCategoryIndex, setDisplayedCategoryIndex] = useState(0);

  const uniqueRoles = _uniqBy(data.matchingRoles, (x) => x.name);

  const sortedData = _orderBy(uniqueRoles, (d) => d.value, 'desc');
  const topItems = sortedData.slice(0, MAX_ITEMS);
  const filteredData = sortedData.filter((item) => topItems.includes(item));
  const chartData = filteredData.map((item) => ({
    label: item.name,
    value: item.value,
  }));

  const selectedRole = filteredData[displayedCategoryIndex]!;

  return (
    <div>
      <ResponsiveContainer width="99%" height={375}>
        <RadarChart cx="50%" cy="50%" outerRadius="45%" data={chartData}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="label"
            tick={
              // @ts-ignore
              <CustomLabel onClick={setDisplayedCategoryIndex} selectedIndex={displayedCategoryIndex} />
            }
          />
          <PolarRadiusAxis domain={[30, 100]} />
          <Radar dataKey="value" stroke="#4890e0" fill="#4890e0" scale={15} fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>

      {selectedRole.occupationInfo && (
        <div className="bg-brand-blue/60 rounded-2xl p-4">
          <div className="mb-2 flex items-center justify-between">
            <h3 className="text-xl font-bold">{selectedRole.name}</h3>
            <Button
              variant="ghost"
              onClick={() => window.open(selectedRole.occupationInfo!.link, '_blank', 'noopener noreferrer')}
            >
              <Icon name="role-link-icon" />
            </Button>
          </div>

          <p className="prose text-primary mb-3">{selectedRole.occupationInfo?.description}</p>

          {/* {!!selectedRole.likes?.length && (
            <ListWithTitle
              icon={<Icon name="like" />}
              title={t('orientation.talent_passport.tabs.matching_roles.why_you_would_like')}
              items={selectedRole.likes.map((x) => ({ title: x }))}
            />
          )}

          {!!selectedRole.dislikes?.length && (
            <ListWithTitle
              icon={<Icon name="dislike" />}
              title={t('orientation.talent_passport.tabs.matching_roles.why_you_would_dislike')}
              items={selectedRole.dislikes.map((x) => ({ title: x }))}
            />
          )} */}

          {!!selectedRole.formation?.localizedFormationItems?.length && (
            <ListWithTitle
              icon={<Icon name="training-icon" />}
              title={t('orientation.talent_passport.tabs.matching_roles.training')}
              items={selectedRole.formation.localizedFormationItems.map((x) => ({
                title: x.title,
                description: x.text,
              }))}
            />
          )}

          {!!selectedRole.certificationsDto?.localizedCertificationItems?.length && (
            <ListWithTitle
              icon={<Icon name="certifications-icon" />}
              title={t('orientation.talent_passport.tabs.matching_roles.certifications')}
              items={selectedRole.certificationsDto?.localizedCertificationItems.map((x) => ({
                title: x.title,
                description: x.text,
              }))}
            />
          )}
        </div>
      )}
    </div>
  );
};
