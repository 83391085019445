import cx from 'clsx';

export type ProgressBarProps = {
  className?: string;
  value: number;
  duration?: 200 | 500 | 700 | 1000;
  color?: string;
  ariaLabel?: string;
};

export const ProgressBar = ({ className, value, color, duration = 200, ariaLabel }: ProgressBarProps) => {
  const normalizedValue = Math.min(Math.max(Math.round(value), 0), 100);

  return (
    <div
      className={cx('h-2.5 w-full rounded-full bg-white', className)}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={normalizedValue}
      aria-label={ariaLabel}
    >
      <div
        className={cx('h-2.5 rounded-full transition-all', {
          'duration-200': duration === 200,
          'duration-500': duration === 500,
          'duration-700': duration === 700,
          'duration-1000': duration === 1000,
          'bg-brand-pink': !color,
        })}
        style={{ width: normalizedValue + '%', backgroundColor: color }}
      />
    </div>
  );
};
