import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useIntlState } from '@interskillar/localization';
import { withArtificialDelay } from 'utils/promises';

import { useApi } from './api';
import { paths } from './types';

type PersonalitySurveyStatus =
  paths['/api/personality-assessment/current/status']['get']['responses']['200']['content']['application/json'];

export const usePersonalitySurveyStatus = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['personalitySurveyStatus'],

    queryFn: async () => {
      const response = await api!.get('personality-assessment/current/status');

      return response.json<PersonalitySurveyStatus>();
    },

    enabled: !!api,
  });
};

export type PersonalitySurveyQuestions =
  paths['/api/personality-assessment/survey']['get']['responses']['200']['content']['application/json'];

export type PersonalitySurveyQuestion = NonNullable<PersonalitySurveyQuestions['questionFlows'][string]>[number];

export const usePersonalitySurveyQuestions = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['personalitySurveyQuestions', mappedLanguage],

    queryFn: async () => {
      const response = await api!.get('personality-assessment/survey', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<PersonalitySurveyQuestions>();
    },

    enabled: !!api,
  });
};

export const useStartPersonalitySurvey = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await api!.post('personality-assessment/start');

      return response.json<PersonalitySurveyStatus>();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['personalitySurveyStatus'], data);
    },
  });
};

export type AnswerPersonalitySurveyQuestionBody =
  paths['/api/personality-assessment/current/answer']['post']['requestBody']['content']['application/json'];
type AnswerPersonalitySurveyQuestionResponse =
  paths['/api/personality-assessment/current/answer']['post']['responses']['200']['content']['application/json'];

export const useAnswerPersonalitySurveyQuestion = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: AnswerPersonalitySurveyQuestionBody) => {
      const response = await withArtificialDelay(
        api!.post('personality-assessment/current/answer', {
          json: body,
        }),
        import.meta.env.DEV ? 0 : 500,
      );

      return response.json<AnswerPersonalitySurveyQuestionResponse>();
    },

    onSuccess: (data) => {
      queryClient.setQueryData(['personalitySurveyStatus'], data);
    },
  });
};

export const useCompletePersonalityAssessment = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await withArtificialDelay(api!.post('personality-assessment/complete'), 1500);

      return response.json();
    },
    onSuccess: async (data) => {
      queryClient.setQueryData(['personalitySurveyStatus'], data);
      await queryClient.invalidateQueries(['assessmentsStatus']);
      await queryClient.invalidateQueries(['onboardingStatus']);
      await queryClient.invalidateQueries(['results']);
    },
  });
};

export type PersonalitySurveyResults =
  paths['/api/assessment-outputs/personality']['get']['responses']['200']['content']['application/json'];

export const usePersonalitySurveyResults = () => {
  const api = useApi();
  const { mappedLanguage } = useIntlState();

  return useQuery({
    queryKey: ['results', 'personalitySurveyResults', mappedLanguage],

    queryFn: async () => {
      const response = await api!.get('assessment-outputs/personality', {
        searchParams: new URLSearchParams({
          languageCode: mappedLanguage,
        }),
      });

      return response.json<PersonalitySurveyResults>();
    },

    enabled: !!api,
  });
};
