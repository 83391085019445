import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, useTranslate } from '@interskillar/localization';
import { Heading, Button, LeftIcon } from '@interskillar/ui';
import { CAREER_ORIENTATION_PAGE, EXPERIENCE_SURVEY_PAGE } from 'consts/pages';
import { clearLatestSurvey, setLatestSurvey } from 'utils/storage';
import { useCompletePersonalityAssessment, useUserProfile } from 'services/api';
import { SurveySteps } from 'routes/CareerOrientationPage/SurveySteps';

export const AllSetPage = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const userProfileQuery = useUserProfile();
  const completePersonalityAssessmentMutation = useCompletePersonalityAssessment();

  const userId = userProfileQuery.data!.id;

  useEffect(() => {
    setLatestSurvey(userId, 'complete');
  }, [userId]);

  const handleSubmit = async () => {
    try {
      await completePersonalityAssessmentMutation.mutateAsync(undefined);

      navigate(CAREER_ORIENTATION_PAGE.path, {
        replace: true,
      });

      clearLatestSurvey(userId);
    } catch (_) {
      toast.error(t('orientation.personality_survey.error_submitting_survey'));
    }
  };

  const handlePreviousQuestion = () => {
    navigate(EXPERIENCE_SURVEY_PAGE.path, {
      replace: true,
    });
  };

  return (
    <div className="max-w-xl pb-14">
      <SurveySteps step={3} initialAnimate />

      <Heading isHighlighted font="body" className="mb-6 mt-6 justify-center text-center">
        {t('orientation.personality_survey.submit_screen.title')}
      </Heading>

      <div className="prose text-primary text-center">
        <FormattedMessage id="orientation.personality_survey.submit_screen.description" values={{ br: <br /> }} />
      </div>

      <div className="mb-20 mt-10 flex justify-center gap-2">
        <Button variant="outline" size="lg" onClick={handlePreviousQuestion}>
          <LeftIcon className="me-2" />
          {t('orientation.survey.back')}
        </Button>

        <Button
          variant="green"
          isLoading={completePersonalityAssessmentMutation.isLoading}
          size="lg"
          onClick={handleSubmit}
          data-testid="complete-button"
        >
          {t('orientation.survey.submit')}
        </Button>
      </div>
    </div>
  );
};
