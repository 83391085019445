import * as React from 'react';
import cx from 'clsx';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Spinner } from '@interskillar/ui';
import { IntlProvider, getInitialLanguage, useTranslations } from '@interskillar/localization';
import { useIsBackendDown, useUserProfile } from 'services/api';
import { useOnboardingStatus } from 'services/api';
import { ViewportDebug } from 'components/ViewportDebug';

const initialLanguage = getInitialLanguage();

const CenteredContainer = ({ className, children }: { className?: string; children: React.ReactNode }) => (
  <div className={cx('absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2', className)}>{children}</div>
);

export const Root = () => {
  const authData = useAuth();
  const isBackendDown = useIsBackendDown();

  const translationsQuery = useTranslations(initialLanguage);
  const profileQuery = useUserProfile();
  const onboardingStatusQuery = useOnboardingStatus();

  const isDesktop = window.matchMedia('(min-width: 768px)').matches;

  if (isBackendDown.data === true) {
    return (
      <CenteredContainer className="w-full px-4 md:max-w-lg">
        <h1 className="text-center text-3xl font-bold">We'll be back soon!</h1>
        <div className="text-center text-lg">
          We are currently undergoing maintenance.
          <br /> Please try again later.
        </div>
      </CenteredContainer>
    );
  }

  if (
    authData.isLoading ||
    !authData.userData ||
    translationsQuery.isLoading ||
    profileQuery.isLoading ||
    isBackendDown.isLoading ||
    onboardingStatusQuery.isLoading
  ) {
    return (
      <CenteredContainer>
        <Spinner size="large" />
      </CenteredContainer>
    );
  }

  if (profileQuery.isError || onboardingStatusQuery.isError) {
    return (
      <CenteredContainer className="w-full p-8 text-center">
        <div className="font-bold text-red-400">Something went wrong. Please try again later.</div>

        <Button variant="green" onClick={() => authData.signOutRedirect()}>
          Click here to try again
        </Button>
        {import.meta.env.DEV && <div className="text-sm text-red-500">{(profileQuery.error as Error)?.message}</div>}
      </CenteredContainer>
    );
  }

  return (
    <IntlProvider>
      <Outlet />
      <ToastContainer
        position={isDesktop ? 'bottom-right' : 'top-center'}
        bodyClassName="md:text-lg"
        autoClose={3000}
      />

      {import.meta.env.DEV && <ViewportDebug />}
    </IntlProvider>
  );
};
