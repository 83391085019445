import { type Policy } from 'components/Authorize';
import {
  ALL_ORGANIZATIONS_PAGE,
  CAREER_ORIENTATION_PAGE,
  HOME_PAGE,
  INTERVIEWS_PAGE,
  MENTORS_PAGE,
  MY_CLASSES_PAGE,
  MY_STUDENTS_PAGE,
  SETTINGS_PAGE,
  SINGLE_ORGANIZATION_PAGE,
  USER_MANAGEMENT_PAGE,
} from 'consts/pages';

export const NAVIGATION_ITEMS: {
  page: typeof HOME_PAGE | typeof SINGLE_ORGANIZATION_PAGE;
  isOnMainMenu: boolean;
  isComingSoon?: boolean;
  policy: Policy;
}[] = [
  { page: HOME_PAGE, isOnMainMenu: true, policy: HOME_PAGE.policy },
  { page: USER_MANAGEMENT_PAGE, isOnMainMenu: false, policy: USER_MANAGEMENT_PAGE.policy },
  { page: MY_CLASSES_PAGE, isOnMainMenu: true, policy: MY_CLASSES_PAGE.policy },
  { page: CAREER_ORIENTATION_PAGE, isOnMainMenu: true, policy: CAREER_ORIENTATION_PAGE.policy },
  { page: MENTORS_PAGE, isOnMainMenu: false, isComingSoon: false, policy: MENTORS_PAGE.policy },
  { page: INTERVIEWS_PAGE, isOnMainMenu: false, isComingSoon: true, policy: INTERVIEWS_PAGE.policy },
  { page: ALL_ORGANIZATIONS_PAGE, isOnMainMenu: false, policy: ALL_ORGANIZATIONS_PAGE.policy },
  { page: SINGLE_ORGANIZATION_PAGE, isOnMainMenu: true, policy: SINGLE_ORGANIZATION_PAGE.policy },
  { page: MY_STUDENTS_PAGE, isOnMainMenu: false, policy: MY_STUDENTS_PAGE.policy },
  { page: SETTINGS_PAGE, isOnMainMenu: true, policy: SETTINGS_PAGE.policy },
];
