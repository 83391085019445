import { Link as RRLink } from 'react-router-dom';

import { Authorize } from 'components/Authorize';
import { FormattedMessage, useTranslate } from '@interskillar/localization';
import { Heading, RightIcon, Link, Separator, InputWithLabel, Spinner } from '@interskillar/ui';
import { useDiversityAssessment, useDiversityAssessmentCodebooks, useUserProfile } from 'services/api';
import { SETTINGS_CHANGE_EMAIL_PAGE, SETTINGS_CHANGE_PASSWORD_PAGE, SETTINGS_DELETE_ACCOUNT_PAGE } from 'consts/pages';

import { ProfilePictureSection } from './ProfilePictureSection';
import { AccountSettingsForm } from './AccountSettingsForm';
import { ProfileForm } from './ProfileForm';

export const AccountSettingsPage = () => {
  const t = useTranslate();
  const { data } = useUserProfile();
  const codebooksQuery = useDiversityAssessmentCodebooks();
  const diversityAssessmentQuery = useDiversityAssessment();

  if (codebooksQuery.isFetching || diversityAssessmentQuery.isFetching) {
    return (
      <div className="absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2">
        <Spinner size="large" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div className="max-w-xl pb-14">
      <Heading iconName="settings" className="mb-6">
        {t('settings.title')}
      </Heading>

      <div>
        <ProfilePictureSection profile={data} />

        <Heading type="h2" font="body" className="mb-2 mt-6">
          {t('settings.account_information_subtitle')}
        </Heading>

        <div className="mb-4 flex flex-col gap-2 md:flex-row">
          <InputWithLabel
            className="flex-1"
            label={t('settings.email_label')}
            id="email-input"
            value={data.emailAddress}
            readOnly
            disabled
            info={
              <Link as={RRLink} to={SETTINGS_CHANGE_EMAIL_PAGE.path} className="inline-flex items-center gap-1">
                {t('settings.change_email_address')}
                <RightIcon />
              </Link>
            }
          />

          <InputWithLabel
            className="flex-1"
            label={t('settings.password_label')}
            id="password-input"
            value="********"
            readOnly
            disabled
            info={
              <Link as={RRLink} to={SETTINGS_CHANGE_PASSWORD_PAGE.path} className="inline-flex items-center gap-1">
                {t('settings.change_password_address')}
                <RightIcon />
              </Link>
            }
          />
        </div>

        <AccountSettingsForm profile={data} />

        <Separator className="mb-2 mt-4" />

        <Authorize policy={{ singlePolicy: 'InterSkillar.Pages.MyProfile' }}>
          <ProfileForm />

          <Separator className="mb-2 mt-4" />
        </Authorize>

        <Heading type="h2" font="body" className="mb-4">
          {t('settings.delete_account_subtitle')}
        </Heading>

        <p>
          <FormattedMessage
            id="settings.delete_account_text"
            values={{
              link: (chunks) => (
                <Link as={RRLink} to={SETTINGS_DELETE_ACCOUNT_PAGE.path} type="danger">
                  {chunks}
                </Link>
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};
