import cx from 'clsx';

export type SeparatorProps = {
  className?: string;
  label?: string;
};

export const Separator = ({ className, label }: SeparatorProps) => {
  return (
    <div className={cx(className, 'relative flex h-6 items-center justify-center')}>
      <hr className="h-px w-full border-0 bg-gray-200" />

      {label && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4">{label}</div>
      )}
    </div>
  );
};
