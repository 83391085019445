import * as React from 'react';

import { useTranslate } from '@interskillar/localization';
import { Heading, PageLoader } from '@interskillar/ui';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { CAREER_ORIENTATION_PAGE } from 'consts/pages';
import { useTrendsAndInsights } from 'services/api';

// const SwipeableContainer = ({ children }: { children: React.ReactNode }) => {
//   return (
//     <div className="flex snap-x snap-mandatory items-stretch gap-2 overflow-auto md:grid md:snap-none md:grid-cols-2 md:gap-3 lg:grid-cols-3">
//       {children}
//     </div>
//   );
// };

// const SwipeableItem = ({ children, backgroundColor }: { children: React.ReactNode; backgroundColor: string }) => {
//   return (
//     <div
//       className="flex w-[70vw] min-w-[70vw] snap-center flex-col items-center rounded-xl p-4 shadow md:w-[initial] md:min-w-[initial]"
//       style={{ backgroundColor }}
//     >
//       {children}
//     </div>
//   );
// };

export const TrendsAndInsightsPage = () => {
  const t = useTranslate();
  const trendsAndInsightsQuery = useTrendsAndInsights();

  if (trendsAndInsightsQuery.isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="pb-14 ">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.career_orientation',
            href: CAREER_ORIENTATION_PAGE.path,
          },
          {
            label: 'breadcrumbs.trends_and_insights',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading iconName="fire">{t('orientation.trends.title')}</Heading>

      <h2 className="mb-2 text-xl font-medium">{t('orientation.trends.market_insights_subtitle')}</h2>

      {/* <SwipeableContainer>
        {trendsAndInsightsQuery.data?.marketInsights.map((item, index) => (
          <SwipeableItem key={index} backgroundColor={item.colorBackground}>
            <Icon altName={item.logo} className="mb-4 mt-8 text-7xl" color={item.colorPrimary} />

            <h3 className="mb-4 text-center text-lg font-medium">{item.localizedName}</h3>

            <p className="prose whitespace-normal text-center">{item.localizedContent}</p>
          </SwipeableItem>
        ))}
      </SwipeableContainer>

      <h2 className="mb-2 mt-8 text-xl font-medium">{t('orientation.trends.work_environment_subtitle')}</h2>

      <SwipeableContainer>
        {trendsAndInsightsQuery.data?.workEnvironmentMotivationPersonalities.map((item, index) => (
          <SwipeableItem key={index} backgroundColor={item.colorBackground}>
            <Icon altName={item.logo} className="mb-4 text-7xl" color={item.colorPrimary} />

            <h3 className="text-center text-lg font-medium">{item.localizedTitle}</h3>

            <p className="prose mb-4 flex-1 whitespace-normal text-center">{item.localizedDescription}</p>

            <div className="flex gap-2">
              <Icon name="tip" className="mt-1 min-w-[32px] text-3xl" color={item.colorPrimary} />

              {item.localizedTip}
            </div>
          </SwipeableItem>
        ))}
      </SwipeableContainer> */}
    </div>
  );
};
