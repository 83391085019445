import * as z from 'zod';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Heading,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  Input,
  FormMessage,
  Button,
} from '@interskillar/ui';
import { SETTINGS_PAGE } from 'consts/pages';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { isHTTPError, parseHTTPError, useChangeEmail } from 'services/api';
import { FormattedMessage, useTranslate } from '@interskillar/localization';

const validationSchema = z
  .object({
    newEmail: z.string().email({
      message: 'validation.invalid_email',
    }),
    confirmEmail: z.string().email({
      message: 'validation.invalid_email',
    }),
  })
  .refine((data) => data.newEmail === data.confirmEmail, {
    message: 'validation.emails_dont_match',
    path: ['confirmEmail'],
  });

type FormValues = z.infer<typeof validationSchema>;

export const ChangeEmailPage = () => {
  const t = useTranslate();
  const changeEmailMutation = useChangeEmail();

  const form = useForm<FormValues>({
    defaultValues: {
      newEmail: '',
      confirmEmail: '',
    },
    resolver: zodResolver(validationSchema),
  });

  const handleFormSubmit = (data: FormValues) => {
    changeEmailMutation
      .mutateAsync({
        newEmail: data.newEmail,
      })
      .then(() => {
        toast.success(() => (
          <div>
            <FormattedMessage
              id="settings.change_email.success_message"
              values={{
                br: () => <br />,
              }}
            />
          </div>
        ));
      })
      .catch(async (error) => {
        if (isHTTPError(error)) {
          const err = await parseHTTPError<{ errors: Record<keyof FormValues, string[]> }>(error);

          for (const el of Object.keys(err.errors)) {
            const castedEl = el as keyof FormValues;
            form.setError(castedEl, {
              message: err.errors[castedEl][0],
            });
          }
        }
      });
  };

  return (
    <div className="pb-14">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.settings',
            href: SETTINGS_PAGE.path,
          },
          {
            label: 'breadcrumbs.change_email',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading iconName="settings" className="mb-4">
        {t('settings.change_email.title')}
      </Heading>

      <Form {...form}>
        <form className="max-w-md space-y-4" onSubmit={form.handleSubmit(handleFormSubmit)}>
          <FormField
            control={form.control}
            name="newEmail"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.change_email.new_email_label')}</FormLabel>
                  <FormControl>
                    <Input id="new-email-input" autoComplete="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="confirmEmail"
            render={({ field }) => {
              return (
                <FormItem className="flex-1">
                  <FormLabel>{t('settings.change_email.confirm_email_label')}</FormLabel>
                  <FormControl>
                    <Input id="confirm-email-input" autoComplete="off" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className="flex justify-end pt-2">
            <Button
              type="submit"
              variant="green"
              isLoading={changeEmailMutation.isLoading}
              disabled={!form.formState.isDirty}
            >
              {t('settings.change_email.submit_button_text')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
