'use client';

import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { useCurrentLocale } from '@interskillar/localization';

import { cn } from './utils';
import { LeftIcon } from './LeftIcon';
import { RightIcon } from './RightIcon';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  const locale = useCurrentLocale();

  return (
    <DayPicker
      locale={locale}
      showOutsideDays={showOutsideDays}
      className={cn('bg-input p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-base text-primary font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn('text-primary h-7 w-7 bg-transparent p-0 opacity-70 hover:opacity-100'),
        nav_button_previous: 'absolute start-1',
        nav_button_next: 'absolute end-1',
        table: 'w-full border-collapse space-y-1 h-[310px]',
        head_row: 'flex',
        head_cell: 'text-subtle rounded-md w-10 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          'h-10 w-10 p-0 text-primary font-normal aria-selected:opacity-100 text-base hover:bg-brand-yellow hover:text-contrast',
        ),
        day_selected: 'bg-brand-yellow !text-contrast hover:bg-brand-yellow/90',
        day_today: 'font-bold text-brand-pink',
        day_outside: 'text-primary opacity-50',
        day_disabled: 'text-primary opacity-50',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <LeftIcon className="h-4 w-4" />,
        IconRight: () => <RightIcon className="h-4 w-4" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
