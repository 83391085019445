import { type ToolboxResponse } from 'services/api';
import { Icon } from '@interskillar/ui';

import { useTranslate } from '@interskillar/localization';

import { Tile } from './Tile';

export const TipsTile = ({ tips }: { tips: ToolboxResponse['tips'] }) => {
  const t = useTranslate();

  return (
    <Tile title={tips.title} icon={tips.logo}>
      <div className="mb-8 flex flex-col items-center">
        <Icon altName={tips.innerLogo1!} className="text-brand-blue text-5xl" />
        <div className="mb-4 mt-4 text-center font-bold">{t('orientation.toolbox.tips.cv_title')}</div>

        <div className="text-subtle mb-2 font-bold">{t('orientation.toolbox.tips.cv_subtitle1')}</div>
        <p className="mb-4 text-center">{t('orientation.toolbox.tips.cv_description1')}</p>

        <ul className="mb-8 space-y-2">
          {tips.tips.cv.points.map((point) => (
            <li key={point} className="flex items-start gap-3">
              <div className="mt-1 min-h-[1rem] min-w-[1rem] rounded-full border-2 border-white" />

              {point}
            </li>
          ))}
        </ul>

        <div className="text-subtle mb-2 font-bold">{t('orientation.toolbox.tips.cv_subtitle2')}</div>
        <p className="mb-4 text-center">{tips.tips.cv.localizedDescription}</p>
      </div>

      <div className="flex flex-col items-center">
        <Icon altName={tips.innerLogo2!} className="text-brand-blue text-5xl" />
        <div className="mb-4 mt-4 text-center font-bold">{t('orientation.toolbox.tips.interview_title')}</div>

        <div className="text-subtle mb-2 font-bold">{t('orientation.toolbox.tips.interview_subtitle1')}</div>

        <p className="mb-4 text-center">{tips.tips.interview.localizedDescription}</p>

        <div className="text-subtle mb-2 font-bold">{t('orientation.toolbox.tips.interview_subtitle2')}</div>

        <ul className="mb-8 space-y-2">
          {tips.tips.interview.points.map((point) => (
            <li key={point} className="flex items-start gap-3">
              <div className="mt-1 min-h-[1rem] min-w-[1rem] rounded-full border-2 border-white" />
              {point}
            </li>
          ))}
        </ul>
      </div>
    </Tile>
  );
};
