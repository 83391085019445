import { useQuery } from '@tanstack/react-query';

import { paths, useApi } from './api';

export type MyClassesResponse = paths['/api/my-classes']['get']['responses']['200']['content']['application/json'];

export const useMyClasses = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['myClasses'],
    queryFn: async () => {
      const response = await api!.get(`my-classes`);

      return response.json<MyClassesResponse>();
    },
    enabled: !!api,
  });
};

export type PendingInvitationsResponse =
  paths['/api/my-classes/pending-invitations']['get']['responses']['200']['content']['application/json'];

export const usePendingInvitations = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['myClasses', 'pendingInvitations'],
    queryFn: async () => {
      const response = await api!.get(`my-classes/pending-invitations`);

      return response.json<PendingInvitationsResponse>();
    },
    enabled: !!api,
  });
};
