import { type Policy } from 'components/Authorize';
import { type IconProps } from '@interskillar/ui';
import { type UserProfile } from 'services/api';

type PageInNavigation = {
  path: string;
  menuLabelTKey: string;
  iconName: IconProps['name'];
  policy: Policy;
};

type DynamicPageInNavigation = {
  getPath: (profile: UserProfile) => string;
  routerPath: string;
  menuLabelTKey: string;
  iconName: IconProps['name'];
  policy: Policy;
};

export const HOME_PAGE: PageInNavigation = {
  path: '/',
  menuLabelTKey: 'navigation.home',
  iconName: 'home',
  policy: {
    anyPolicy: [
      'InterSkillar.Pages.StudentHomePage',
      // 'InterSkillar.Pages.CoachHomePage',
      // 'InterSkillar.Pages.ProgramManagerHomePage',
      // 'InterSkillar.Pages.AdminHomePage',
    ],
  },
};

export const CAREER_ORIENTATION_PAGE: PageInNavigation = {
  path: '/career-orientation',
  menuLabelTKey: 'navigation.orientation',
  iconName: 'career-orientation-icon',
  policy: { singlePolicy: 'InterSkillar.Pages.CareerOrientation' },
};

export const MENTORS_PAGE: PageInNavigation = {
  path: '/mentors',
  menuLabelTKey: 'navigation.mentors',
  iconName: 'message',
  policy: { singlePolicy: 'InterSkillar.Pages.Mentors' },
};

export const INTERVIEWS_PAGE: PageInNavigation = {
  path: '/interviews',
  menuLabelTKey: 'navigation.interviews',
  iconName: 'suitcase',
  policy: { singlePolicy: 'asd' },
};

export const SETTINGS_PAGE: PageInNavigation = {
  path: '/settings',
  menuLabelTKey: 'navigation.settings',
  iconName: 'settings',
  policy: { singlePolicy: 'InterSkillar.Pages.AccountSettings' },
};

export const USER_MANAGEMENT_PAGE: PageInNavigation = {
  path: '/user-management',
  menuLabelTKey: 'navigation.user_management',
  iconName: 'users',
  policy: { singlePolicy: 'InterSkillar.Pages.UserManagement' },
};

export const ALL_ORGANIZATIONS_PAGE: PageInNavigation = {
  path: `/organizations`,
  menuLabelTKey: 'navigation.organizations',
  iconName: 'organizations-icon',
  policy: { singlePolicy: 'InterSkillar.Pages.AllOrganizations' },
};

export const SINGLE_ORGANIZATION_PAGE: DynamicPageInNavigation = {
  getPath(profile) {
    return `${ALL_ORGANIZATIONS_PAGE.path}/${profile.organizationId}`;
  },
  routerPath: `${ALL_ORGANIZATIONS_PAGE.path}/:organizationId`,
  menuLabelTKey: 'navigation.my_organization',
  iconName: 'organizations-icon',
  policy: { singlePolicy: 'InterSkillar.Pages.MyOrganization' },
};

export const MY_CLASSES_PAGE: PageInNavigation = {
  path: `/my-classes`,
  menuLabelTKey: 'navigation.my_classes',
  iconName: 'classes-icon',
  policy: { singlePolicy: 'InterSkillar.Pages.MyClasses' },
};

export const MY_STUDENTS_PAGE: PageInNavigation = {
  path: `/my-students`,
  menuLabelTKey: 'navigation.my_students',
  iconName: 'my-students-icon',
  policy: { singlePolicy: 'InterSkillar.Pages.MyStudents' },
};

export const CLASS_DETAILS_PAGE = {
  getPath(organizationId: number, classId: number) {
    return `/organizations/${organizationId}/classes/${classId}`;
  },
  routerPath: '/organizations/:organizationId/classes/:classId',
};

export const CLASS_STUDENT_DETAILS_PAGE = {
  getPath(organizationId: number, classId: number, studentId: string) {
    return `/organizations/${organizationId}/classes/${classId}/student/${studentId}`;
  },
  routerPath: '/organizations/:organizationId/classes/:classId/student/:studentId',
  matchPath: /^\/?organizations\/.+\/classes\/.+\/student\/.+\/?$/i,
};

export const SETTINGS_CHANGE_EMAIL_PAGE = {
  path: `${SETTINGS_PAGE.path}/change-email`,
};

export const SETTINGS_CHANGE_PASSWORD_PAGE = {
  path: `${SETTINGS_PAGE.path}/change-password`,
};

export const SETTINGS_DELETE_ACCOUNT_PAGE = {
  path: `${SETTINGS_PAGE.path}/delete-account`,
};

export const PERSONALITY_SURVEY_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/personality-survey`,
};

export const MOTIVATIONS_SURVEY_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/motivations-survey`,
};

export const EXPERIENCE_SURVEY_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/experience-survey`,
};

export const ALL_SET_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/all-set`,
};

export const CAREER_ORIENTATION_PERSONALITY_RESULTS_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/personality-results`,
};

export const CAREER_ORIENTATION_MOTIVATIONS_RESULTS_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/motivations-results`,
};

export const TRENDS_AND_INSIGHTS_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/trends-and-insights`,
};

export const TALENT_PASSPORT_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/talent-passport`,
};

export const TALENT_PASSPORT_CAREER_KICKOFF_PAGE = {
  path: `${TALENT_PASSPORT_PAGE.path}/career-kickoff`,
};

export const TALENT_PASSPORT_STRIVE_FOR_GREATNESS_PAGE = {
  path: `${TALENT_PASSPORT_PAGE.path}/strive-for-greatness`,
};

export const TOOLBOX_PAGE = {
  path: `${CAREER_ORIENTATION_PAGE.path}/toolbox`,
};
