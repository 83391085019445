import { type ReactNode } from 'react';

import { Collapse, Icon } from '@interskillar/ui';
import { ExpandButton } from 'components/ExpandButton';
import { useToggle } from 'utils/hooks';

type TileProps = {
  title?: string;
  icon?: string;
  children?: ReactNode;
};

export const Tile = ({ title, icon, children }: TileProps) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div className="rounded-2xl border border-gray-400 shadow-[0px_2px_10px] shadow-gray-800">
      <div
        className="grid grid-cols-[50px_1fr_50px] items-center px-4 py-3 pe-2"
        role="button"
        onClick={() => toggleIsOpen()}
      >
        <Icon altName={icon} className="text-brand-blue min-w-[2rem]" />
        <div className="text-primary font-medium">{title}</div>
        <ExpandButton className="text-primary w-[50px] !px-2" isOpen={isOpen} onToggle={() => {}} />
      </div>

      <Collapse isOpen={isOpen}>
        <div className="p-4">{children}</div>
      </Collapse>
    </div>
  );
};
