import * as React from 'react';
import cx from 'clsx';

export type CheckboxProps = {
  id: string;
  className?: string;
  isChecked?: boolean;
  label?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  name?: string;
  isDisabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, className, isChecked, label, size = 'medium', name, isDisabled, onBlur, onChange }, ref) => {
    return (
      <div className={cx(className, 'flex')}>
        <input
          ref={ref}
          id={id}
          type="checkbox"
          value=""
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          className="text-brand-yellow accent-brand-yellow focus:ring-brand-yellow bg-input mt-1 h-4 min-h-[1rem] w-4 min-w-[1rem] rounded border-gray-300 outline-none focus:ring-2"
          onBlur={onBlur}
          onChange={onChange}
        />
        <label
          htmlFor={id}
          className={cx('ms-3 font-medium', {
            'text-sm': size === 'small',
            'text-base': size === 'medium',
            'text-lg': size === 'large',
            'text-subtle': isDisabled,
            'text-primary': !isDisabled,
          })}
        >
          {label}
        </label>
      </div>
    );
  },
);
