import { useTranslate } from '@interskillar/localization';
import { Heading } from '@interskillar/ui';
import { type TalentPassportResponse } from 'services/api';

import { IconDataCard } from './common/IconDataCard';
import { CategoryAndCareersSection } from './common/CategoryAndCareersSection';

export const TalentPassportResults = ({ data }: { data: TalentPassportResponse }) => {
  const t = useTranslate();

  return (
    <div className="max-w-xl pb-14">
      <p className="prose text-primary mb-4">{t('orientation.talent_passport.description')}</p>

      <Heading type="h2" className="mb-4">
        {t('orientation.talent_passport.career_kickoff.subtitle')}
      </Heading>

      <div className="mb-6 space-y-4">
        <IconDataCard
          icon={data!.careerKickOffIntroduction.firstLogo}
          description={data!.careerKickOffIntroduction.localizedFirstDescription}
        />

        <IconDataCard
          icon={data!.careerKickOffIntroduction.secondLogo}
          description={data!.careerKickOffIntroduction.localizedSecondDescription}
        />
      </div>

      <CategoryAndCareersSection data={data} />
    </div>
  );
};
