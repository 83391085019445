import * as React from 'react';
import cx from 'clsx';

import { PersonalitySurveyQuestion } from 'services/api';

export type SingleAnswerQuestionProps = {
  question: PersonalitySurveyQuestion;
  selectedAnswer?: number | null;
  preview?: boolean;
  onNextQuestion?: (questionId: number, answerId: number) => void;
};

const SurveyCard = ({
  text,
  name,
  value,
  preview,
  className,
  selectedAnswer,
  onNextQuestion,
}: {
  text: string;
  name: string;
  value: number;
  preview: boolean;
  className?: string;
  selectedAnswer?: number | null;
  onNextQuestion?: (questionId: number, answerId: number) => void;
}) => {
  return (
    <label
      className={cx(
        'block w-full',
        {
          'cursor-pointer': !preview,
        },
        className,
      )}
    >
      <input
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
            onNextQuestion?.(Number(name), Number(value));
          }
        }}
        data-testid="survey-answer-option"
        onChange={() => {}}
        checked={selectedAnswer ? Number(selectedAnswer) === Number(value) : false}
        type="radio"
        readOnly={preview}
        value={value}
        className="peer sr-only"
      />

      <p
        className={cx(
          'prose ring-brand-yellow peer-checked:bg-brand-yellow peer-checked:border-brand-yellow peer-checked:text-contrast text-primary rounded-2xl border border-gray-300 bg-transparent px-4 py-3 font-medium',
          {
            'peer-focus:ring-2': !preview,
          },
        )}
      >
        {text}
      </p>
    </label>
  );
};

export const SingleAnswerQuestion = ({
  question,
  selectedAnswer,
  preview = false,
  onNextQuestion,
}: SingleAnswerQuestionProps) => {
  return (
    <div id={`survey-question-${question?.questionId}`}>
      <div className="prose text-primary mb-4 text-center">{question?.localizedText}</div>

      <div className="space-y-2">
        {question.localizedAnswers.map((answer) => (
          <SurveyCard
            key={answer.questionAnswerId}
            text={answer.localizedText}
            preview={preview}
            value={answer.questionAnswerId}
            name={answer.questionId.toString()}
            selectedAnswer={selectedAnswer}
            onNextQuestion={onNextQuestion}
          />
        ))}
      </div>
    </div>
  );
};
