import { useCallback, useContext } from 'react';
import { FeedbackContext, type FeedbackType } from './FeedbackContext';

export const useFeedbackModal = () => {
  const { selectedType, setSelectedType } = useContext(FeedbackContext);

  const openFeedbackModal = useCallback(
    (type: FeedbackType) => {
      setSelectedType(type);
    },
    [setSelectedType],
  );

  const changeFeedbackType = useCallback(
    (type: FeedbackType) => {
      setSelectedType(type);
    },
    [setSelectedType],
  );

  const closeFeedbackModal = useCallback(() => {
    setSelectedType(null);
  }, [setSelectedType]);

  return {
    selectedType,
    openFeedbackModal,
    changeFeedbackType,
    closeFeedbackModal,
  };
};
