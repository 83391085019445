import { Control, useController, Path, FieldValues } from 'react-hook-form';

import { Select, SelectProps } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

type Option = {
  label: string;
  value: number;
  options?: Option[];
};

const findOptionByValue = (options: Option[], value: number): Option | null => {
  for (const option of options) {
    if (option.value === value) {
      return option;
    }

    if (option.options) {
      const found = findOptionByValue(option.options, value);

      if (found) {
        return found;
      }
    }
  }

  return null;
};

export function ControlledSelect<T extends FieldValues>({
  label,
  info,
  options,
  name,
  id,
  dataTestId,
  control,
  isMulti,
  menuIsOpen,
  limit = Infinity,
  placeholder,
  onFocus,
  tabIndex,
}: SelectProps & { name: Path<T>; limit?: number; control: Control<T> }) {
  const t = useTranslate();
  const {
    field: { value, onChange, ...rest },
    fieldState: { error },
  } = useController<T, Path<T>>({ name, control });

  const val = isMulti
    ? ((value || []) as number[]).map((v) => findOptionByValue(options as Option[], v))
    : findOptionByValue(options as Option[], value as number);

  return (
    <Select
      dataTestId={dataTestId}
      value={val}
      isOptionDisabled={isMulti ? () => (val as []).length >= limit : undefined}
      onChange={(v: Option | Option[]) => {
        isMulti && Array.isArray(v) ? onChange(v.map((o) => o.value)) : onChange((v as Option).value);
      }}
      isRounded={false}
      onFocus={onFocus}
      id={id}
      menuIsOpen={menuIsOpen}
      isClearable={false}
      isMulti={isMulti}
      placeholder={placeholder}
      label={label}
      info={info}
      options={options}
      tabIndex={tabIndex}
      closeMenuOnSelect={isMulti ? false : true}
      error={error?.message && t(error.message)}
      {...rest}
    />
  );
}
