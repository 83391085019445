import { useQuery } from '@tanstack/react-query';

import { paths, useApi } from './api';

type OnboardingStatusResponse =
  paths['/api/dashboard/onboarding-status']['get']['responses']['200']['content']['application/json'];

export const useOnboardingStatus = () => {
  const api = useApi();

  return useQuery({
    queryKey: ['onboardingStatus'],
    queryFn: async () => {
      const response = await api!.get('dashboard/onboarding-status');

      return response.json<OnboardingStatusResponse>();
    },
    enabled: !!api,
  });
};
