import { Navigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';

const getRedirectTo = (state: string | undefined) => {
  if (!state) {
    return '/';
  }

  let parsedState: { redirectTo?: string } = {};

  try {
    parsedState = JSON.parse(state) as { redirectTo?: string };
  } catch (_error) {
    /* empty */
  }

  if (!parsedState.redirectTo) {
    return '/';
  }

  if (['/auth/callback', '/auth/logout'].some((x) => parsedState.redirectTo?.startsWith(x))) {
    return '/';
  }

  return parsedState.redirectTo || '/';
};

export const AuthCallbackPage = () => {
  const { userData } = useAuth();

  const redirectTo = getRedirectTo(userData?.state as string);

  return <Navigate to={redirectTo} />;
};
