import { enGB } from 'date-fns/locale/en-GB';
import { fr } from 'date-fns/locale/fr';
import { nlBE } from 'date-fns/locale/nl-BE';
import { arMA } from 'date-fns/locale/ar-MA';

import { useIntlState } from './useIntlState';

const locales = {
  en: enGB,
  'fr-BE': fr,
  'nl-BE': nlBE,
  'ar-MA': arMA,
};

export const useCurrentLocale = () => {
  const { language } = useIntlState();

  return locales[language] || enGB;
};
