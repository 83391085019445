import { memo, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

import { Icon, type IconProps, Dialog, DialogHeader, DialogTitle, DialogContent, Button } from '@interskillar/ui';
import { useFeedbackModal, useSendFeedback, type FeedbackType } from 'services/feedback';
import { useUserProfile } from 'services/api';
import { useTranslate } from '@interskillar/localization';

type TabProps = {
  name: string;
  type: FeedbackType;
  isActive: boolean;
  iconName: IconProps['name'];
  onClick: () => void;
  isLoading?: boolean;
};

const Tab = memo(({ name, type, isActive, isLoading, iconName, onClick }: TabProps) => {
  return (
    <div
      className={clsx(
        `text-primary relative flex-1 rounded-full px-2 py-1.5 text-center text-sm font-medium outline-sky-400 transition focus-visible:outline-2`,
      )}
      role="button"
      onClick={onClick}
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {isActive && (
        <motion.div
          layoutId={isLoading ? undefined : 'bubble'}
          className={clsx('absolute inset-0 z-10 rounded-lg bg-gradient-to-r', {
            'from-red-500 to-red-500/70': type === 'bug',
            'from-purple-500 to-purple-500/70': type === 'idea',
            'from-green-500 to-green-500/70': type === 'general',
          })}
          transition={{ type: 'spring', bounce: 0.1, duration: 0.5 }}
        />
      )}
      <span className="text-primary relative z-10 inline-flex items-center gap-1 pt-1 transition duration-300">
        <Icon name={iconName} className="text-lg lg:text-2xl" />
        {name}
      </span>
    </div>
  );
});

const FeedbackHeader = memo(({ isLoading }: { isLoading: boolean }) => {
  const t = useTranslate();
  const { selectedType, changeFeedbackType } = useFeedbackModal();

  return (
    <div className="flex items-center">
      <Tab
        type={selectedType!}
        isLoading={isLoading}
        name={t('support.tab.general')}
        iconName="general-feedback"
        isActive={selectedType === 'general'}
        onClick={() => changeFeedbackType('general')}
      />
      <Tab
        type={selectedType!}
        isLoading={isLoading}
        name={t('support.tab.idea')}
        iconName="bulb"
        isActive={selectedType === 'idea'}
        onClick={() => changeFeedbackType('idea')}
      />
      <Tab
        type={selectedType!}
        isLoading={isLoading}
        name={t('support.tab.bug')}
        iconName="bug"
        isActive={selectedType === 'bug'}
        onClick={() => changeFeedbackType('bug')}
      />
    </div>
  );
});

const FeedbackContent = () => {
  const t = useTranslate();
  const [message, setMessage] = useState('');
  const userProfileQuery = useUserProfile();
  const sendFeedbackMutation = useSendFeedback();
  const { selectedType, closeFeedbackModal } = useFeedbackModal();

  const placeholderText =
    selectedType === 'general'
      ? t('support.textarea.general_placeholder')
      : selectedType === 'idea'
        ? t('support.textarea.idea_placeholder')
        : t('support.textarea.bug_placeholder');

  const handleSubmit = () => {
    sendFeedbackMutation.mutate(
      {
        feedbackType: selectedType!,
        feedbackMsg: message,
        feedbackEmail: userProfileQuery.data!.id,
        feedbackSrc: window.location.pathname,
      },
      {
        onSuccess: () => {
          toast.success(t('support.success_message'));
          closeFeedbackModal();
        },
        onError: () => {
          toast.error(t('support.error_message'));
        },
      },
    );
  };

  return (
    <>
      <textarea
        className="bg-input text-primary mb-2 mt-4 h-56 w-full resize-none rounded-lg border border-gray-300 p-4"
        placeholder={placeholderText}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <div className="flex justify-end">
        <Button
          variant="green"
          className="w-full md:w-fit "
          isLoading={sendFeedbackMutation.isLoading}
          disabled={message.length === 0}
          onClick={handleSubmit}
        >
          {t('support.submit_button')}
        </Button>
      </div>
    </>
  );
};

export const FeedbackModal = () => {
  const t = useTranslate();
  const sendFeedbackMutation = useSendFeedback();
  const { selectedType, closeFeedbackModal } = useFeedbackModal();

  return (
    <Dialog open={selectedType !== null} onOpenChange={closeFeedbackModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('support.modal.title')}</DialogTitle>
        </DialogHeader>

        <FeedbackHeader isLoading={sendFeedbackMutation.isLoading} />

        <FeedbackContent />
      </DialogContent>
    </Dialog>
  );
};
