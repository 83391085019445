import { FormattedMessage, useTranslate } from '@interskillar/localization';
import { Heading } from '@interskillar/ui';
import { type PersonalitySurveyResults } from 'services/api';

import { LearningStyleMatrix } from './LearningStyleMatrix';

export const LearningStyle = ({ learningStyle }: { learningStyle: PersonalitySurveyResults['learningStyle'] }) => {
  const t = useTranslate();

  return (
    <div>
      <Heading iconName="book" className="mb-4" type="h2" font="body">
        {t('orientation.personality_survey.results.learning_style.title')}
      </Heading>

      <p className="prose text-primary mb-8">
        <FormattedMessage
          id="orientation.personality_survey.results.learning_style.description"
          values={{
            br: <br />,
          }}
        />
      </p>

      <LearningStyleMatrix learningStyle={learningStyle} />

      <h3 className="mb-6 mt-8 flex items-center gap-2 text-2xl font-medium">
        <div className="h-6 w-6 rounded-full" style={{ backgroundColor: learningStyle.color }} />
        <FormattedMessage
          id="orientation.personality_survey.results.learning_style.you_are"
          values={{
            value: learningStyle.title,
          }}
        />
      </h3>

      <div className="space-y-6">
        {learningStyle.localizedLearningStyleBehaviors.map((behavior) => (
          <div key={behavior.id} className="flex gap-2">
            <div className="border-brand-yellow mt-1 h-max rounded-full border-[2px] p-[3px]">
              <div className="bg-brand-yellow h-[10px] w-[10px] rounded-full" />
            </div>
            <div>
              <div className="italic">{behavior.title}</div>
              <p className="prose text-primary prose-sm">{behavior.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
