import { useIntlState } from '@interskillar/localization';
import { Icon } from './Icon';

type LeftIconProps = {
  className?: string;
  forceLeft?: boolean;
};

export const LeftIcon = ({ className, forceLeft }: LeftIconProps) => {
  const { direction } = useIntlState();

  return <Icon className={className} name={direction === 'rtl' && !forceLeft ? 'chevron-right' : 'chevron-left'} />;
};
