import * as React from 'react';
import cx from 'clsx';

export type AvatarProps = {
  isRounded?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  src: string;
  alt: string;
};

export const Avatar = ({ isRounded, size = 'medium', src, alt, className }: AvatarProps) => {
  return (
    <div className={className}>
      <img
        src={src}
        alt={alt}
        className={cx('object-cover', {
          'rounded-lg': !isRounded,
          'rounded-full': isRounded,
          'h-[3rem] w-[3rem] min-w-[3rem]': size === 'small',
          'h-[4rem] w-[4rem] min-w-[4rem]': size === 'medium',
          'h-[7rem] w-[7rem] min-w-[7rem]': size === 'large',
        })}
        referrerPolicy="no-referrer"
      />
    </div>
  );
};
