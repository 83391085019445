import Cookie from 'js-cookie';

const getLatestSurveyKey = (userId: string) => `${userId}---is___latestSurvey`;

export type LatestSurveyOptions = 'personality' | 'motivation' | 'experience' | 'complete';

const isTestUser = import.meta.env.VITE_OIDC_CLIENT_ID === 'InterSkillar_FrontendApp_e2e';

const personalitySurveyStorage = {
  setItem: (userId: string, value: string) => {
    const key = getLatestSurveyKey(userId);

    if (isTestUser) {
      Cookie.set(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  },
  getItem: (userId: string) => {
    const key = getLatestSurveyKey(userId);

    if (isTestUser) {
      return Cookie.get(key);
    } else {
      return localStorage.getItem(key);
    }
  },
  removeItem: (userId: string) => {
    const key = getLatestSurveyKey(userId);

    if (isTestUser) {
      Cookie.remove(key);
    } else {
      localStorage.removeItem(key);
    }
  },
};

export function setLatestSurvey(userId: string, latestSurvey: LatestSurveyOptions) {
  personalitySurveyStorage.setItem(getLatestSurveyKey(userId), latestSurvey);
}

export function getLatestSurvey(userId: string): LatestSurveyOptions | null {
  return personalitySurveyStorage.getItem(getLatestSurveyKey(userId)) as LatestSurveyOptions | null;
}

export function clearLatestSurvey(userId: string) {
  personalitySurveyStorage.removeItem(getLatestSurveyKey(userId));
}
