import * as z from 'zod';

const stringifiedNumber = (schema: z.ZodNumber | z.ZodEffects<z.ZodNumber, number, number>) =>
  z.preprocess((num) => {
    if (typeof num === 'string') {
      return parseInt(num, 10);
    }
    return num;
  }, schema);

const requiredMessage = 'validation.required_field';
export const atLeastOneMessage = 'validation.select_at_least_one';
const invalidOptionMessage = 'validation.select_at_least_one';
const validPostalCodeMessage = 'validation.invalid_postal_code';
const validAgeMessage = 'validation.invalid_age';

const requiredNumber = (message: string) =>
  z.number({
    invalid_type_error: message,
    required_error: message,
  });

const requiredString = (message: string) =>
  z.string({
    invalid_type_error: message,
    required_error: message,
  });

export const validationSchema = z
  .object({
    genderId: requiredNumber(requiredMessage),
    ethnicityIds: z
      .array(z.number(), {
        invalid_type_error: atLeastOneMessage,
        required_error: atLeastOneMessage,
      })
      .min(1, atLeastOneMessage),
    sexualOrientationId: requiredNumber(requiredMessage),
    educationLevelId: requiredNumber(requiredMessage),
    fieldOfStudyIds: z
      .array(z.number(), {
        required_error: atLeastOneMessage,
        invalid_type_error: atLeastOneMessage,
      })
      .min(1, atLeastOneMessage),
    age: stringifiedNumber(
      requiredNumber(requiredMessage)
        .min(18, validAgeMessage)
        .max(99, validAgeMessage)
        .refine((val) => !isNaN(val), {
          message: validAgeMessage,
        }),
    ),
    postalCode: requiredString(requiredMessage).refine((val) => val.length > 1, {
      message: validPostalCodeMessage,
    }),
    limitationInDailyActivitiesId: stringifiedNumber(requiredNumber(requiredMessage)),
    relationshipStatusId: stringifiedNumber(requiredNumber(invalidOptionMessage)),
    familyStatusId: stringifiedNumber(requiredNumber(invalidOptionMessage)),
  })
  .nonstrict();
