import { useState } from 'react';

import { type ToolboxResponse } from 'services/api';
import { useTranslate } from '@interskillar/localization';

import { Tile } from './Tile';
import { ScrollableIconSection } from './ScrollableIconSection';

export const PersonalDevelopmentTile = ({
  personalDevelopment,
}: {
  personalDevelopment: ToolboxResponse['personalDevelopment'];
}) => {
  const t = useTranslate();
  const [index, setIndex] = useState(0);

  const realIndex =
    ((index % personalDevelopment.personalDevelopment.topics.length) +
      personalDevelopment.personalDevelopment.topics.length) %
    personalDevelopment.personalDevelopment.topics.length;

  const currentTopic = personalDevelopment.personalDevelopment.topics[realIndex];

  return (
    <Tile title={personalDevelopment.title} icon={personalDevelopment.logo}>
      <div className="text-primary mb-4 text-center text-lg font-bold">
        {t('orientation.toolbox.personal_development.title1')}
      </div>

      <ScrollableIconSection
        icon={currentTopic.logo}
        onBack={() => setIndex((x) => x - 1)}
        onForward={() => setIndex((x) => x + 1)}
      >
        <div className="text-center font-bold">{currentTopic.localizedTitle}</div>
        <p className="prose prose-sm text-primary text-center">{currentTopic.localizedDescription}</p>
      </ScrollableIconSection>

      <div className="text-primary mb-4 mt-6 text-center text-lg font-bold">
        {t('orientation.toolbox.personal_development.title2')}
      </div>

      <ul className="mb-8 space-y-2">
        <li className="flex items-start gap-3">
          <div className="border-primary mt-1 min-h-[1rem] min-w-[1rem] rounded-full border-2" />

          <p className="prose prose-sm text-primary">{personalDevelopment.personalDevelopment.points.text1}</p>
        </li>
        <li className="flex items-start gap-3">
          <div className="border-primary mt-1 min-h-[1rem] min-w-[1rem] rounded-full border-2" />

          <p className="prose prose-sm text-primary">{personalDevelopment.personalDevelopment.points.text2}</p>
        </li>
      </ul>
    </Tile>
  );
};
