import { useMutation } from '@tanstack/react-query';
import { useApi } from './api';

export const useAcceptInviteToClass = (organizationId: number, classId: number) => {
  const api = useApi();

  return useMutation({
    mutationFn: () => {
      return api!.post(`organizations/${organizationId}/classes/${classId}/invitations/accept`);
    },
  });
};

export const useDeclineInviteToClass = (organizationId: number, classId: number) => {
  const api = useApi();

  return useMutation({
    mutationFn: () => {
      return api!.post(`organizations/${organizationId}/classes/${classId}/invitations/decline`);
    },
  });
};
