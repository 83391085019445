import ky, { HTTPError } from 'ky';
import type { Options } from 'ky';
import { useMemo } from 'react';
import { useAuth } from 'oidc-react';
import { useIntlState } from '@interskillar/localization';

export * from './types';

const options: Options = {
  prefixUrl: `${import.meta.env.VITE_BACKEND_URL}/api/`,
  headers: {
    'content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const useApi = () => {
  const { userData, userManager } = useAuth();
  const { mappedLanguage } = useIntlState();

  const token = userData?.access_token;

  return useMemo(() => {
    if (!token) {
      return null;
    }

    return ky.create({
      ...options,
      headers: {
        ...(options.headers || {}),
        Authorization: `Bearer ${token}`,
        'Accept-Language': mappedLanguage,
      },
      hooks: {
        afterResponse: [
          async (_, __, response) => {
            if (response.status === 401) {
              await userManager.signoutRedirect();
            }
          },
        ],
      },
    });
  }, [mappedLanguage, token, userManager]);
};

export const isHTTPError = (error: unknown): error is HTTPError => {
  return error instanceof HTTPError;
};

export async function parseHTTPError<T>(error: HTTPError) {
  const body = await (error.response.json() as Promise<T>);

  return body;
}
