import clsx from 'clsx';
import { type ReactNode, type ElementType, type ComponentPropsWithoutRef } from 'react';

type LinkProps<T extends ElementType> = {
  children: ReactNode;
  as?: T;
  type?: 'primary' | 'secondary' | 'danger';
  className?: string;
};

export function Link<T extends ElementType = 'a'>({
  as,
  type = 'primary',
  className,
  children,
  ...props
}: LinkProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof LinkProps<T>>) {
  const Element = as || 'a';

  return (
    <Element
      {...props}
      className={clsx(
        'font-medium hover:underline',
        {
          'text-brand-pink': type === 'primary',
          'text-gray-500': type === 'secondary',
          'text-red-500': type === 'danger',
        },
        className,
      )}
    >
      {children}
    </Element>
  );
}
