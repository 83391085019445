import { type ReactNode } from 'react';
import { Link as RRLink } from 'react-router-dom';

import { getDefaultImage } from 'utils/profile';
import { CAREER_ORIENTATION_PAGE } from 'consts/pages';
import { useOnboardingStatus, useUserProfile } from 'services/api';
import { Avatar, Card, Link, ProgressBar } from '@interskillar/ui';
import { FormattedMessage, useTranslate } from '@interskillar/localization';

import { MentorsSection } from './MentorsSection';
import { PendingInvitations } from './PendingInvitations';

const ProgressContainer = ({ children }: { children: ReactNode }) => {
  return <Card className="rounded-3xl px-4 py-6 shadow-[0px_2px_10px] shadow-black/10">{children}</Card>;
};

const OnboardingProgressBar = () => {
  const t = useTranslate();
  const onboardingStatusQuery = useOnboardingStatus();

  const progress = onboardingStatusQuery.data?.onboardingPercentage ?? 100;

  if (progress >= 100) {
    return null;
  }

  if (progress === 0) {
    return (
      <ProgressContainer>
        <strong>{t('home.onboarding_welcome_title')}</strong> <br />
        <Link as={RRLink} to={CAREER_ORIENTATION_PAGE.path} className="mt-1">
          {t('home.start_by_filling_assessments')}
        </Link>
      </ProgressContainer>
    );
  }

  const {
    isPersonalityAssessmentCompleted,
    isMotivationAssessmentCompleted,
    isExperienceAssessmentCompleted,
    isSectorAssessmentCompleted,
  } = onboardingStatusQuery?.data || {};

  const numOfCompletedAssessments = [
    isPersonalityAssessmentCompleted,
    isMotivationAssessmentCompleted,
    isExperienceAssessmentCompleted,
    isSectorAssessmentCompleted,
  ].filter(Boolean).length;

  return (
    <ProgressContainer>
      <div className="text-lg font-bold">
        {t('home.profile_completion_progress_header', {
          value: numOfCompletedAssessments,
        })}
      </div>

      <div className="text-subtle mb-2">
        {t('home.profile_completion_progress_subtitle', {
          value: progress,
        })}
      </div>

      <div className="mb-4 leading-[0.5]">
        <FormattedMessage
          id="home.click_here_to_continue"
          values={{
            link: (...chunks: ReactNode[]) => (
              <Link
                as={RRLink}
                to={CAREER_ORIENTATION_PAGE.path}
                className="text-brand-pink mb-4 mt-1 inline-block underline"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </div>

      <ProgressBar value={progress} />
    </ProgressContainer>
  );
};

export const StudentHomePage = () => {
  const { data } = useUserProfile();

  if (!data) {
    return null;
  }

  return (
    <div className="max-w-lg">
      <div className="mb-8 flex items-center">
        <Avatar
          isRounded
          size="small"
          src={data.profilePicture || getDefaultImage(data.firstName, data.lastName)}
          alt="Profile picture"
        />
        <div className="ms-3 leading-5">
          <FormattedMessage
            id="home.welcome_text"
            values={{
              b: (...chunks: ReactNode[]) => <b className="font-bold">{chunks}</b>,
              br: () => <br />,
              firstName: data.firstName,
            }}
          />
        </div>
      </div>

      <PendingInvitations />

      <OnboardingProgressBar />

      <MentorsSection />
    </div>
  );
};
