import { useState, memo } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { Heading } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { type TalentPassportResponse } from 'services/api';

import { AbilitiesChart } from './AbilitiesChart';
import { RolesChart } from './RolesChart';

type TabType = 'skills' | 'careers';

type TabProps = {
  name: string;
  type: TabType;
  isActive: boolean;
  onClick: () => void;
};

const Tab = memo(({ name, type, isActive, onClick }: TabProps) => {
  return (
    <div
      className={clsx(
        `relative flex flex-1 items-center justify-center rounded-full px-2 py-3 text-center font-medium text-gray-800 outline-sky-400 transition focus-visible:outline-2`,
      )}
      role="button"
      onClick={onClick}
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {isActive && (
        <motion.div
          layoutId="talentPassportTab"
          className={clsx('absolute inset-0 z-10 rounded-l bg-gradient-to-r', {
            'from-brand-blue to-brand-blue': type === 'careers',
            'from-brand-pink to-brand-pink': type === 'skills',
          })}
          transition={{ type: 'tween', duration: 0.3 }}
        />
      )}
      <span className="text-primary relative z-10 inline-flex items-center gap-1 transition duration-300">{name}</span>
    </div>
  );
});

export const CategoryAndCareersSection = ({ data }: { data: TalentPassportResponse }) => {
  const t = useTranslate();
  const [selectedTab, setSelectedTab] = useState<TabType>('skills');

  return (
    <div>
      <Heading type="h2" className="mb-4">
        {t('orientation.talent_passport.your_skills.subtitle')}
      </Heading>

      <div className="mb-4 flex items-stretch overflow-clip rounded-lg border-2">
        <Tab
          type="skills"
          name={t('orientation.talent_passport.tabs.your_abilities')}
          isActive={selectedTab === 'skills'}
          onClick={() => {
            setSelectedTab('skills');
          }}
        />
        <Tab
          type="careers"
          name={t('orientation.talent_passport.tabs.matching_roles')}
          isActive={selectedTab === 'careers'}
          onClick={() => {
            setSelectedTab('careers');
          }}
        />
      </div>

      <p className="text-subtle text-center">{t('orientation.talent_passport.click_on_an_item')}</p>

      <div className="min-h-[500px] md:min-h-[600px]">
        {selectedTab === 'skills' ? <AbilitiesChart data={data} /> : <RolesChart data={data} />}
      </div>
    </div>
  );
};
